.main-header {
  font-family: ProductSans-Bold, sans-serif;
  font-size: 36px;
  text-align: center;
  margin: auto;
  color: #2C2C59;
  font-weight: 700;
  max-width: 50%;
}

.main-subtitle {
  font-family: ProductSans-Regular, sans-serif;
  font-size: 15px;
  text-align: center;
  margin: auto;
  color: #2C2C59;
}
.align-column-right {
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
}
.align-column-left {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
@media (max-width: 752px)  {
  .align-column-right {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }

  .align-column-left {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }
}



.help-card {
  width: 61%;
  height: 308px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 50px 70px #3707C10D;
  border-radius: 20px;
  border-color: white;
  opacity: 1;
}
.help-card-text {

  text-align: center;
  font-family: ProductSans-Regular, sans-serif;
  font-size: 13px;
  letter-spacing: 0.25px;
  color: #2C2C59;
  opacity: 0.9;
}
.getting_started_btn {
  text-align: left;
  font-family: ProductSans-Medium, sans-serif;
  font-size: 9px;
  letter-spacing: 0;
  color: #2C2C59;
  background: #CBDFFF 0 0 no-repeat padding-box;
  border-radius: 46px;
  border: 1px #CBDFFF;
  opacity: 1;
}
.getting_started_btn:hover {

  color: #ffffff;
  background: #CBDFFF 0 0 no-repeat padding-box;
  border-radius: 46px;
  border: 1px #CBDFFF;
}

/** Header Js **/
.icon-arrow {
  height: 14px;
  font-size: 9px;
  opacity: 1;
  color: #1FA5EA;
}
.header-text {
  color: #1FA5EA;
  font-family: ProductSans-Medium, sans-serif;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  opacity: 0.9;
}

.header-subtitle {
  font-family: ProductSans-Regular, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  color: #2C2C59;
  opacity: 0.8;
}


/** Inside Apply Loan and Guarantor's Guide **/

.gs-main_card {
  margin-left: 5%;
  margin-right: 5%;
  border: 2px white solid;
  border-radius: 20px;
  background: white;
}

.gs-btn {
  font-family: ProductSans-Regular, sans-serif;
  width: 150px;
  height: 37px;
  background: #EAF3FD 0 0 no-repeat padding-box;
  border: 0 solid;
  border-radius: 5px;
  opacity: 1;
}
.gs-btn:hover {
  color: #2C2C59;
  font-family: ProductSans-Regular, sans-serif;
  font-size: 14px;
  background-color: #EAF3FD;
}
.help-nav_item{
  font-size: 12px;
  color: #2C2C59;
  font-family: ProductSans-Regular, sans-serif;
  text-indent: 1.4rem;
  text-align: left;
  letter-spacing: 0.40px;
}

.help-nav_item.not-active {
  opacity: 0.4;
}
.help-nav_item.active{
  border-left: 2px #1FA5EA solid;
  opacity: 0.9;
}

.help-nav_item:hover{
  color: #2C2C59;
  box-shadow: -2px 16px 6px #fef3fc;
  opacity: 0.9;
}
.step-card {
  border-left: 1px #2c2c591f solid;
}

.nav-horizontal {
  border: 1.8px #707070 solid;
  display: block;
  overflow: hidden;
  width: 9%;
  margin-right: 8px;
}
.nav-horizontal:hover {
  border: 1.8px #ecf4ff solid;
}
.nav-horizontal.active {
  border: 1.8px #c355d6 solid;
}

.help-topic {
  font-family: ProductSans-Bold, sans-serif;
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.6px;
  color: #2C2C59;
  opacity: 1;
}
.help-content {
  font-family: "ProductSans-Light", sans-serif;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.6px;
  color: #2C2C59;
  opacity: 0.8;
}
.top-img {
  position: absolute;
  left: 40px;
  top: -95px;
  z-index: 1;
}
.apply-btn {
  color: #FFFFFF;
  font-size: 13px;
  background: #120A44 0 0 no-repeat padding-box;
  opacity: 1;
}

.page {
  margin-left: 20px;
}
.circleNum {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  background: #FCF2FD;
  border: 1px solid #FCF2FD;
  color: #EF52FE;
  text-align: center;
  font: 25px ProductSans-Bold, sans-serif;
}

@media (max-width: 790px) {
  .main-header {
    font-size: 20px;
  }

  .main-subtitle {
    font-size: 12px;
  }
}

@media (max-width: 790px) {
  .top-img {
    position: unset;
    z-index: 0;
  }
}