@media (min-width: 1600px) and (max-width: 1990px) {
    .z-landing-div h1 {
        font-size: 68px;
        line-height: 80px;
        margin-bottom: 55px;
    }

    .z-landing-image {
        min-height: 118vh;
        height: 895px;
    }
    .z-getting_help-image {
        min-height: 118vh;
        height: 895px;
    }
}

@media (min-width: 1420px) {
    .z-dashboard-panel {
        /* min-height: 28vh;
        height: 330px; */
        width: 84% !important;
    }

    .z-card-arrowl {
        left: 25%;
    }

    .z-card-arrowR {
        right: 25%;
    }

    .check-circle {
        right: 30%;
    }
}

@media (min-width: 1248px) and (max-width: 1420px) {
    .z-landing-image {
        min-height: 83vh;
        height: 788px;
    }

    .z-getting_help-image {
        min-height: 83vh;
        height: 788px;
    }

    .z-landing-div {
        margin: 0 14% 0 20%;
    }

    .z-landing-div h1 {
        font-size: 58px;
        margin-top: 18%;
    }

    .z-navbar {
        padding: 20px 7%;
    }
    .z-landing-div {
        margin: 10% 11% 0 23%;
        height: 370px;
        min-height: 16vh;
    }

    .z-navbar-logo {
        height: 38px;
    }

    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 730px;
        min-height: 56vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 3%;
        padding: 0 9px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 7% 8% 0 15%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
        margin-top: 1%;
    }

    .z-section-div h2 {
        font-size: 40px;
        line-height: 46px;
        margin-top: 12%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 515px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 8%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 600px;
        min-height: 60vh;
    }

    .z-section-padding2 {
        margin: 2% 10% 0 5%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 515px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 4%;
        padding-right: 2%;
        margin-top: 14%;
    }

    .z-res-margin {
        margin-top: 7%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 690px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 690px;
        width: 50%;
        margin-top: 3%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 5%;
    }

    .z-test-res {
        margin: 0 15%;
        width: 70%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }
    .z-res-flex {
        width: auto;
    }

    .z-testimonial-parag {
        margin: 5% 0 0;
        padding: 0 26%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 38vh;
        height: 350px;
    }

    .z-scroll {
        width: 4%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 38px;
    }

    .z-footer-terms {
        font-size: 18px;
    }

    .z-footer-header {
        font-size: 28px;
        margin-bottom: 10%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 0;
    }

    .z-footer-subscribe p {
        font-size: 28px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 18px;
        padding: 6px 33% 6px 25px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 18px !important;
        width: 175px;
    }

    .z-footer-res {
        /*width: 70%;
        margin-left: 30%;*/
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 885px;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 0;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 6%;
    }
    .z-login-btn {
        font-size: 14px !important;
        width: 230px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 190px !important;
        max-width: 190px !important;
        min-width: 190px !important;
        width: 190px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 20%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 33vh;
        height: 320px;
        width: 84% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 140px;
    }
    .z-pan-top {
        margin-top: 15%;
    }

    .z-tab-active {
        width: 190px;
        height: 155px;
        top: -16%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }
    .z-nav-header-p {
        margin-left: 6%;
        font-size: 13px;
        width: 50%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
        /*background: rgb(74,255,222);*/

        background: #28428a 0% 0% no-repeat padding-box;
        border-radius: 36px;
        letter-spacing: 0;
        color: #ffffff;
        opacity: 0.9;

        /*background: linear-gradient(90deg, rgba(74,255,222,1) 0%, rgba(99,174,255,1) 100%, rgba(229,199,255,1) 100%);*/
    }
    .z-res-12 {
        width: 54%;
    }

    .z-apply-side {
        width: 50%;
        min-height: 30vh;
    }

    .z-admin-panel {
        min-height: 54vh;
        height: 440px;
    }
    .z-graph-res {
        width: 80%;
    }

    .z-admin-panel-graph {
        min-height: 60vh;
        height: 700px;
    }

    .z-width-70 {
        width: 100%;
    }

    .z-admin-panel2 {
        min-height: 153vh;
        height: 2000px;
    }

    .z-table-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0;
        border: 0;
    }

    .z-company-card {
        min-height: 150vh;
    }

    .z-custom2 {
        width: 562px !important;
        border-radius: 69px;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-nav-search-image {
        height: 60px;
        width: 84px;
        margin-right: 35%;
        position: relative;
    }

    .z-profile-card {
        min-height: 149vh !important;
        width: 90%;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 535px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-loan-res {
        width: 70%;
    }

    .z-profile-rres {
        width: 65%;
    }
}

@media (min-width: 1248px) and (max-width: 1360px) {
    .z-landing-image {
        min-height: 83vh;
        height: 788px;
    }

    .z-getting_help-image {
        min-height: 83vh;
        height: 788px;
    }
    .z-landing-div {
        margin: 10% 11% 0 23%;
        height: 370px;
        min-height: 16vh;
    }

    .z-navbar-logo {
        height: 38px;
    }

    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 730px;
        min-height: 56vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 3%;
        padding: 0 9px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 7% 8% 0 15%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
        margin-top: 1%;
    }

    .z-section-div h2 {
        font-size: 40px;
        line-height: 46px;
        margin-top: 12%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 515px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 15%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 600px;
        min-height: 60vh;
    }

    .z-section-padding2 {
        margin: 2% 10% 0 5%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 515px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 4%;
        padding-right: 2%;
        margin-top: 14%;
    }

    .z-res-margin {
        margin-top: 7%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 5%;
    }

    .z-test-res {
        margin: 0 17%;
        width: 66%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }
    .z-res-flex {
        width: auto;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 22%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 38vh;
        height: 347px;
    }

    .z-scroll {
        width: 4%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 38px;
    }

    .z-footer-terms {
        font-size: 18px;
    }

    .z-footer-header {
        font-size: 28px;
        margin-bottom: 10%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 0;
    }

    .z-footer-subscribe p {
        font-size: 28px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 18px;
        padding: 6px 33% 6px 25px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 18px !important;
        width: 175px;
    }

    .z-footer-res {
        /*width: 70%;
        margin-left: 30%;*/
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 760px;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 0;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 50% !important;
    }

    .z-custom .ant-modal-content {
        /*min-height: 48vh;
        height: 510px;*/
        min-height: 45vh;
        height: 510px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 235px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 190px !important;
        max-width: 190px !important;
        min-width: 190px !important;
        width: 190px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 25%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 33vh;
        height: 320px;
        width: 84% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 133px;
    }
    .z-pan-top {
        margin-top: 16%;
    }

    .z-tab-active {
        width: 180px;
        height: 150px;
        top: -14%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }

    .z-nav-header-p {
        font-size: 13px;
        margin-left: 6%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-apply-side {
        width: 52%;
        min-height: 30vh;
    }

    .z-admin-panel {
        min-height: 54vh;
        height: 440px;
    }
    .z-graph-res {
        width: 80%;
    }

    .z-admin-panel-graph {
        min-height: 60vh;
        height: 700px;
    }

    .z-width-70 {
        width: 100%;
    }

    .z-admin-panel2 {
        min-height: 153vh;
        height: 2000px;
    }

    .z-table-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0;
        border: 0;
    }

    .z-company-card {
        min-height: 150vh;
    }

    .z-custom2 {
        width: 562px !important;
        border-radius: 69px;
    }

    .z-custom5 {
        width: 73vh !important;
        border-radius: 69px;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-nav-search-image {
        height: 60px;
        width: 84px;
        margin-right: 35%;
        position: relative;
    }

    .z-new-tab {
        width: 220px;
    }
    .z-res-w-100 {
        width: 100% !important;
    }

    .z-profile-card {
        min-height: 149vh !important;
        width: 90%;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 535px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-custom2 .ant-modal-content {
        width: 98% !important;
    }

    .z-wallet-card {
        width: 60%;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }
}

@media (min-width: 1100px) and (max-width: 1247px) {
    .z-landing-image {
        height: 736px;
        min-height: 95vh;
    }

    .z-getting_help-image {
        /*height: 736px;*/
        /*min-height: 95vh;*/
    }

    .z-navbar-logo {
        height: 35px;
    }

    .z-nav-btn {
        font-size: 12px;
        margin-left: 3%;
    }

    .z-navbar {
        padding: 20px 6%;
        font-size: 13px;
    }

    .z-landing-div h1 {
        font-size: 55px;
        margin-top: 13%;
        margin-bottom: 50px;
    }

    .z-landing-div {
        margin: 13% 11% 0 23%;
        height: 315px;
        min-height: 15vh;
    }

    .z-navbar-logo {
        height: 38px;
    }

    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 660px;
        min-height: 54vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 3%;
        padding: 0 9px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 7% 7% 0 14%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
        margin-top: 3%;
    }

    .z-section-div h2 {
        font-size: 40px;
        line-height: 46px;
        margin-top: 12%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 445px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 8%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 535px;
        min-height: 60vh;
    }

    .z-section-padding2 {
        margin: 8% 10% 0 5%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 480px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 4%;
        padding-right: 2%;
        margin-top: 10%;
    }

    .z-res-margin {
        margin-top: 7%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 5%;
    }

    .z-test-res {
        margin: 0 14%;
        width: 72%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }
    .z-res-flex {
        width: auto;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 22%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 38vh;
        height: 347px;
    }

    .z-scroll {
        width: 4%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 38px;
    }

    .z-footer-terms {
        font-size: 18px;
    }

    .z-footer-header {
        font-size: 28px;
        margin-bottom: 10%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 28px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 18px;
        padding: 6px 33% 6px 25px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 18px !important;
        width: 175px;
    }

    .z-footer-res {
        /*width: 70%;
        margin-left: 30%;*/
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 760px;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 60% !important;
    }

    .z-custom .ant-modal-content {
        min-height: 40vh;
        height: 520px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 136px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 255px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 190px !important;
        max-width: 190px !important;
        min-width: 190px !important;
        width: 190px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 25%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 33vh;
        height: 320px;
        width: 84% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 133px;
    }
    .z-pan-top {
        margin-top: 16%;
    }

    .z-tab-active {
        width: 180px;
        height: 150px;
        top: -14%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }

    .z-nav-header-p {
        font-size: 13px;
        margin-left: 6%;
    }

    .z-nav-search-image {
        height: 63px;
        width: 65px;
        margin-right: 35%;
    }

    .z-mt-18 {
        margin-top: 14%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
        /* min-height: 33vh; */
        /* height: 320px; */
        width: 100% !important;
    }
    .z-res-12 {
        width: 62%;
    }
    .z-res-10 {
        width: 37%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-apply-side {
        width: 74%;
        min-height: 30vh;
    }

    .z-new-tab {
        width: 200px;
    }
    .z-res-w-100 {
        width: 100% !important;
    }

    .z-profile-card {
        min-height: 149vh !important;
        width: 90%;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-loan-res {
        width: 100%;
    }
    .z-custom2 .ant-modal-content {
        width: 75% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-pro-inherit {
        width: 100% !important;
    }

    .z-wallet-card {
        width: 62%;
    }

    .z-profile-rres {
        width: 90%;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }
}

@media (min-width: 1100px) and (max-width: 1170px) {
    .z-landing-image {
        height: 698px;
        min-height: 95vh;
    }

    .z-getting_help-image {
        /*height: 698px;*/
        /*min-height: 95vh;*/
    }

    .z-landing-div h1 {
        font-size: 52px;
        margin-top: 7%;
        margin-bottom: 40px;
        line-height: 62px;
    }

    .z-landing-btn {
        height: 56px !important;
        width: 224px;
        padding: 0 18px;
        font-size: 15px !important;
    }

    .z-navbar-logo {
        height: 30px;
    }

    .z-navbar {
        padding: 20px 7%;
        font-size: 13px;
    }

    .z-nav-btn {
        margin-left: 0;
        font-size: 12px;
    }

    .z-nav-login {
        width: 105px;
    }

    .z-landing-btn {
        height: 54px !important;
        width: 220px;
        padding: 0 18px;
        font-size: 15px !important;
    }
    .z-landing-div {
        margin: 18% 11% 0 23%;
        height: 317px;
        min-height: 15vh;
    }

    .z-navbar-logo {
        height: 34px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 660px;
        min-height: 54vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 7% 6% 0 13%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
        margin-top: 3%;
    }

    .z-section-div h2 {
        font-size: 40px;
        line-height: 46px;
        margin-top: 12%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 430px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 8%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 460px;
        min-height: 60vh;
    }

    .z-section-padding2 {
        margin: 8% 10% 0 5%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 450px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 0;
        padding-right: 0%;
        margin-top: 8%;
    }

    .z-res-margin {
        margin-top: 7%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 14%;
        width: 72%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }
    .z-res-flex {
        width: auto;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 22%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 27vh;
        height: 326px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 38px;
    }

    .z-footer-terms {
        font-size: 18px;
    }

    .z-footer-header {
        font-size: 28px;
        margin-bottom: 10%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 28px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 18px;
        padding: 6px 33% 6px 25px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 18px !important;
        width: 175px;
    }

    .z-footer-res {
        /*width: 70%;
        margin-left: 30%;*/
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 760px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 60% !important;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 255px !important;
    }

    .z-mt-26 {
        margin-top: 22%;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 180px !important;
        max-width: 180px !important;
        min-width: 180px !important;
        width: 180px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 25%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 33vh;
        height: 320px;
        width: 75% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 133px;
    }
    .z-pan-top {
        margin-top: 16%;
    }

    .z-tab-active {
        width: 180px;
        height: 150px;
        top: -14%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }

    .z-nav-header-p {
        font-size: 13px;
        margin-left: 6%;
    }

    .z-nav-search-image {
        height: 60px;
        width: 63px;
        margin-right: 35%;
    }

    .z-nav-header h2 {
        font-size: 24px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }
    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }

    .z-mt-18 {
        margin-top: 14%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
        /* min-height: 33vh; */
        /* height: 320px; */
        width: 100% !important;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-new-tab {
        width: 193px;
    }
    .z-res-w-100 {
        width: 100% !important;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-loan-res {
        width: 100%;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-pro-inherit {
        width: 100% !important;
    }

    .z-wallet-card {
        width: 62%;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image-more {
        left: -327%;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .z-landing-image {
        height: 649px;
        min-height: 85vh;
    }

    .z-getting_help-image {
        height: auto;
    }

    .z-landing-div h1 {
        font-size: 48px;
        margin-top: -5%;
        margin-bottom: 35px;
        line-height: 60px;
    }

    .z-landing-div {
        margin: 0 11% 0 23%;
    }

    .z-navbar-logo {
        height: 30px;
    }

    .z-navbar {
        padding: 20px 7%;
        font-size: 13px;
    }

    .z-nav-btn {
        margin-left: 0;
        font-size: 12px;
    }

    .z-nav-login {
        width: 105px;
    }

    .z-landing-btn {
        height: 54px !important;
        width: 220px;
        padding: 0 18px;
        font-size: 15px !important;
    }
    .z-landing-div {
        margin: 18% 11% 0 23%;
        height: 240px;
        min-height: 15vh;
    }

    .z-navbar-logo {
        height: 34px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 620px;
        min-height: 54vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 6% 4% 0 11%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
        margin-top: 3%;
        padding-right: 5%;
    }

    .z-section-div h2 {
        font-size: 36px;
        line-height: 42px;
        margin-top: 12%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 400px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 3%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 400px;
        min-height: 60vh;
    }

    .z-section-padding2 {
        margin: 12% 10% 0 7%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 400px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 5%;
        padding-right: 0%;
        margin-top: 5%;
    }

    .z-res-margin {
        margin-top: 8%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 525px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 14%;
        width: 72%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 20%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 27vh;
        height: 326px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 17px;
    }

    .z-footer-header {
        font-size: 26px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 26px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 16px;
        padding: 6px 33% 6px 11px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 17px !important;
        width: 175px;
    }

    .z-footer-res {
        width: 70%;
        margin-left: 30%;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 760px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 63% !important;
    }

    .z-custom .ant-modal-content {
        min-height: 40vh;
        height: 500px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 136px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 240px !important;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 180px !important;
        max-width: 180px !important;
        min-width: 180px !important;
        width: 180px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 25%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 33vh;
        height: 330px;
        width: 79% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 133px;
    }
    .z-pan-top {
        margin-top: 17%;
    }

    .z-tab-active {
        width: 180px;
        height: 150px;
        top: -14%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }

    .z-nav-header-p {
        font-size: 13px;
        margin-left: 6%;
    }

    .z-nav-search-image {
        height: 60px;
        width: 63px;
        margin-right: 35%;
    }

    .z-nav-header h2 {
        font-size: 24px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }
    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }
    .z-nav-search {
        width: 185px;
    }

    .z-mt-18 {
        margin-top: 13%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
        /* min-height: 33vh; */
        /* height: 320px; */
        width: 100% !important;
    }
    .z-res-12 {
        width: 65%;
    }
    .z-res-10 {
        width: 34%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-apply-side {
        width: 74%;
        min-height: 30vh;
    }

    .z-new-tab {
        width: 175px;
    }
    .z-res-w-100 {
        width: 100% !important;
    }

    .z-new-dIcon {
        height: 20px;
        width: 20px;
        border: 3px solid #f1f2f2;
    }

    .z-dashboard-progress-div .ant-progress-inner {
        width: 95px !important;
        height: 95px !important;
        font-size: 18px !important;
    }

    .z-label-big {
        color: #3c3c77;
        font-size: 16px;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-loan-res {
        width: 100%;
    }
    .z-custom2 .ant-modal-content {
        height: 75vh;
        /* width: 80% !important; */
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-pro-inherit {
        width: 100% !important;
    }

    .z-wallet-card {
        width: 62%;
    }

    .z-profile-rres {
        width: 100%;
    }

    .z-profile-card {
        min-height: 100vh;
        width: 90%;
        margin: auto;
        background: #fcfcfc;
        padding: 2% 5% !important;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image-more {
        left: -327%;
    }
}

@media (max-width: 1024px) {
    .z-landing-image {
        height: 630px;
        min-height: 45vh;
    }

    .z-getting_help-image {
        height: auto;
    }

    .z-landing-div h1 {
        font-size: 46px;
        margin-top: 0;
        margin-bottom: 36px;
        line-height: 56px;
    }

    .z-landing-div {
        margin: 0 11% 0 21%;
    }

    .z-navbar-logo {
        height: 29px;
    }

    .z-navbar {
        padding: 20px 7%;
        font-size: 13px;
    }

    .z-nav-btn {
        margin-left: 3%;
    }

    .z-landing-btn {
        height: 52px !important;
        width: 210px;
        padding: 0 18px;
        font-size: 14px !important;
    }

    .z-landing-btn {
        height: 50px !important;
        width: 179px;
        font-size: 14px !important;
    }
    .z-landing-div {
        margin: 16% 11% 0 23%;
        height: 240px;
        min-height: 15vh;
    }

    .z-navbar-logo {
        height: 34px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 572px;
        min-height: 54vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 10% 3% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
    }

    .z-section-div h2 {
        font-size: 36px;
        line-height: 42px;
        margin-top: 9%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 395px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 3%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 395px;
        min-height: 15vh;
    }

    .z-section-padding2 {
        margin: 11% 7% 0 7%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 390px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 0;
        padding-right: 0%;
    }

    .z-res-margin {
        margin-top: 8%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 12%;
        width: 76%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 20%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 27vh;
        height: 326px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: row;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 17px;
    }

    .z-footer-header {
        font-size: 26px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 26px;
    }

    .z-footer-input {
        height: 48px;
        font-size: 16px;
        padding: 6px 33% 6px 11px;
    }

    .z-footer-button {
        height: 48px !important;
        padding: 0 !important;
        font-size: 17px !important;
        width: 175px;
    }

    .z-footer-res {
        width: 70%;
        margin-left: 30%;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 53vh;
        height: 760px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 67% !important;
    }

    .z-custom .ant-modal-content {
        min-height: 36vh;
        height: 500px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 136px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 246px !important;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-dashboard .ant-layout-sider {
        background: #000728;
        -webkit-flex: 0 0 190px !important;
        flex: 0 0 180px !important;
        max-width: 180px !important;
        min-width: 180px !important;
        width: 180px !important;
        box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
    }

    .logo {
        height: 22px;
        margin: 8% 15% 25%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-nav-hr hr {
        margin: 4% auto 10%;
    }

    .z-dashboard .ant-menu-item {
        height: 43px;
        font-size: 12px;
        /* color: #EAEAEA; */
    }

    .z-nav-icon {
        height: 27px;
        width: 27px;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 0 7% 0 5%;
    }

    .z-dashboard .ant-layout-content {
        min-height: 200vh !important;
        padding: 2% 5% 3% !important;
    }

    .z-dashboard-panel {
        min-height: 28vh;
        height: 330px;
        width: 84% !important;
    }

    .z-dashboard-label {
        color: rgba(40, 40, 76, 1);
        font-size: 20px;
        font-family: ProductSans-Medium;
        margin-bottom: 2%;
    }

    .z-dashboard-tab {
        width: 133px;
    }
    .z-pan-top {
        margin-top: 17%;
    }

    .z-tab-active {
        width: 180px;
        height: 150px;
        top: -14%;
    }

    .z-naked-btn {
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        margin: 0 !important;
    }

    .z-nav-header-p {
        font-size: 13px;
        margin-left: 6%;
    }

    .z-nav-search-image {
        height: 60px;
        width: 63px;
        margin-right: 35%;
    }

    .z-nav-header h2 {
        font-size: 24px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }
    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }

    .z-nav-search {
        width: 175px;
    }

    .z-mt-18 {
        margin-top: 13%;
    }

    .z-dash-apply {
        min-height: 85vh;
        /* height: 840px; */
        /* min-height: 33vh; */
        /* height: 320px; */
        width: 100% !important;
    }

    .z-res-12 {
        width: 68%;
    }

    .z-res-10 {
        width: 32%;
    }

    .z-apply-side {
        width: 90%;
        min-height: 30vh;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-new-tab {
        width: 175px;
    }
    .z-res-w-100 {
        width: 100% !important;
    }

    .z-new-dIcon {
        height: 20px;
        width: 20px;
        border: 3px solid #f1f2f2;
    }

    .z-dashboard-progress-div .ant-progress-inner {
        width: 95px !important;
        height: 95px !important;
        font-size: 18px !important;
    }

    .z-label-big {
        color: #3c3c77;
        font-size: 16px;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }

    .z-custom2 .ant-modal-content {
        min-height: 36vh;
        height: 75vh;
        width: 83% !important;
    }

    .z-custom5 .ant-modal-content {
        min-height: 78vh;
        height: 500px;
        width: 83% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-pro-inherit {
        width: 100% !important;
    }

    .z-wallet-card {
        width: 70%;
    }

    .z-profile-card {
        min-height: 865px;
        width: 90%;
        margin: auto;
        background: #fcfcfc;
        padding: 2% 5% !important;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
    .z-referral-card-group {
        display: block;
    }
}

@media (max-width: 1000px) {
    .z-landing-div h1 {
        font-size: 43px;
        margin-top: 0;
        margin-bottom: 30px;
        line-height: 52px;
    }

    .z-landing-image {
        height: 570px;
        min-height: 74vh;
    }

    .z-landing-btn {
        height: 50px !important;
        width: 205px;
    }

    .z-nav-login {
        width: 126px;
    }

    .z-nav-btn {
        margin-left: 5%;
    }

    .z-landing-btn {
        height: 50px !important;
        width: 179px;
        font-size: 14px !important;
    }
    .z-landing-div {
        margin: 12% 11% 0 23%;
        height: 240px;
        min-height: 15vh;
    }

    .z-navbar-logo {
        height: 34px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 560px;
        min-height: 74vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 2% 2% 0 9%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
    }

    .z-section-div h2 {
        font-size: 33px;
        line-height: 40px;
        margin-top: 9%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 290px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 3%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 329px;
        min-height: 15vh;
    }

    .z-section-padding2 {
        margin: 20% 6% 0 6%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 300px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 0;
        padding-right: 0%;
    }

    .z-res-margin {
        margin-top: 8%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 12%;
        width: 76%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 20%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 42vh;
        height: 310px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 16px;
    }

    .z-footer-header {
        font-size: 24px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 24px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 35% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 15px !important;
        width: 175px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 100vh;
        height: 840px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 72% !important;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 136px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 253px !important;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    /* .z-dashboard .ant-menu-item {
        padding: 0 !important;
        display: flex;
        justify-content: center;
    }*/
    .ant-menu-inline-collapsed .z-nav-icon {
        height: 32px;
        width: 32px;
        padding: 8% 19%;
    }
    .ant-menu-inline-collapsed > .ant-menu-item > a > span,
    .ant-menu-inline-collapsed > .ant-menu-item > span {
        display: none;
    }

    .z-dashboard .ant-layout-sider-collapsed {
        -webkit-flex: 0 0 80px !important;
        flex: 0 0 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 80px !important;
    }
    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed
        > .ant-menu-item-group
        > .ant-menu-item-group-list
        > .ant-menu-item,
    .ant-menu-inline-collapsed
        > .ant-menu-item-group
        > .ant-menu-item-group-list
        > .ant-menu-submenu
        > .ant-menu-submenu-title,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        left: 0;
        padding: 0 22px !important;
        text-overflow: clip;
    }

    .logo {
        height: 20px;
        margin: 8% 15% 55%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-mt-18 {
        margin-top: 13%;
    }

    .z-tab-active {
        width: 195px;
        height: 160px;
        top: -19%;
    }

    .z-dashboard-tab {
        width: 145px;
    }

    .z-nav-hr hr {
        margin: 15% auto 15%;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }
    .z-display-none {
        display: block;
    }
    .z-nav-none {
        display: none;
    }
    .z-show {
        flex: 0 0 255px !important;
        max-width: 255px !important;
        min-width: 255px !important;
        width: 255px !important;
    }

    .z-show .z-nav-icon {
        height: 35px !important;
        width: 35px !important;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 540px;
    }
    .z-loan-res {
        width: 100%;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-profile-rres {
        width: 100%;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
    .z-nav-header-p {
        width: 65%;
    }

    .z-nav-search-image-more {
        left: -327%;
        top: 40px;
    }
}

@media (max-width: 900px) {
    .z-landing-image {
        height: 495px;
        min-height: 39vh;
    }

    .z-landing-div h1 {
        font-size: 38px;
        margin-top: 0;
        margin-bottom: 24px;
        line-height: 48px;
    }
    .z-landing-div {
        margin: 12% 11% 0 23%;
        height: 240px;
        min-height: 15vh;
    }

    .z-landing-btn {
        height: 50px !important;
        width: 179px;
        font-size: 14px !important;
    }

    .z-navbar-logo {
        height: 34px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 14vh;
    }
    .z-screen-height {
        height: 445px;
        min-height: 65vh;
    }

    .z-nav-btn {
        font-size: 14px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 135px;
        height: 34px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 10vh;
        height: 76px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 2% 2% 0 9%;
    }

    .z-section-div {
        padding-left: 0;
        width: 50%;
    }

    .z-section-div h2 {
        font-size: 33px;
        line-height: 40px;
        margin-top: 9%;
    }

    .z-section-image {
        min-height: 33vh;
        height: 290px;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 3%;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 160px !important;
        height: 46px !important;
        font-size: 14px !important;
    }

    .z-img-overlay-bgg {
        width: 50%;
        background: none;
    }

    .z-section-image-right {
        height: 329px;
        min-height: 15vh;
    }

    .z-section-padding2 {
        margin: 20% 6% 0 6%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-section-image3 {
        height: 300px;
        min-height: 15vh;
    }

    .z-section-div2 {
        padding-left: 0;
        padding-right: 0%;
    }

    .z-res-margin {
        margin-top: 16%;
    }

    .z-section-image-right-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 65vh;
        height: 458px;
        width: 50%;
        margin-top: 4%;
    }

    .z-section-scroll-div i {
        font-size: 28px;
    }

    .z-res-flex {
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    }

    .z-res-h2 {
        padding-left: 0;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 33px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 12%;
        width: 76%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 20%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 40vh;
        height: 290px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 16px;
    }

    .z-footer-header {
        font-size: 24px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 24px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 35% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 15px !important;
        width: 175px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 96vh;
        height: 670px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 3% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 77% !important;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 130px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }
    .z-b-flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-custom .ant-modal-content {
        min-height: 36vh;
        height: 500px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 76vh;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .ant-menu-inline-collapsed .z-nav-icon {
        height: 32px;
        width: 32px;
        padding: 8% 19%;
    }
    .ant-menu-inline-collapsed > .ant-menu-item > a > span,
    .ant-menu-inline-collapsed > .ant-menu-item > span {
        display: none;
    }

    .z-dashboard .ant-layout-sider-collapsed {
        -webkit-flex: 0 0 80px !important;
        flex: 0 0 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 80px !important;
    }
    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed
        > .ant-menu-item-group
        > .ant-menu-item-group-list
        > .ant-menu-item,
    .ant-menu-inline-collapsed
        > .ant-menu-item-group
        > .ant-menu-item-group-list
        > .ant-menu-submenu
        > .ant-menu-submenu-title,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        left: 0;
        padding: 0 22px !important;
        text-overflow: clip;
    }

    .logo {
        height: 20px;
        margin: 8% 15% 55%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-mt-18 {
        margin-top: 13%;
    }

    .z-tab-active {
        width: 195px;
        height: 160px;
        top: -19%;
    }

    .z-dashboard-tab {
        width: 145px;
    }

    .z-nav-hr hr {
        margin: 15% auto 15%;
    }

    .z-dashboard-panel {
        width: 100% !important;
    }

    .z-p-10 {
        padding: 0 12%;
    }

    .z-nav-search {
        width: 145px;
    }

    .z-nav-header h2 {
        font-size: 22px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }

    .z-naked-btn {
        width: 20%;
    }
    .z-res-w-100_ {
        width: 100%;
    }

    .z-new-tab {
        width: 270px;
        height: 186px;
    }
    .z-panel-res {
        height: 375px;
        margin-left: 0 !important;
    }

    .z-dashboard-progress-div {
        height: 200px;
        width: 295px;
    }

    .z-apply-label {
        font-size: 18px;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 530px;
    }

    .z-apply-label-small {
        font-size: 15px;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-wallet-card {
        width: 83%;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
}

@media (max-width: 790px) {
    .z-landing-image {
        height: 440px;
        min-height: 47vh;
    }

    .z-landing-div h1 {
        font-size: 33px;
        margin-top: 0;
        margin-bottom: 24px;
        line-height: 39px;
    }

    .z-landing-btn {
        height: 46px !important;
        width: 180px;
    }
    .z-landing-div {
        margin: 20% 11% 0 23%;
        height: 158px;
        min-height: 15vh;
    }

    .z-landing-btn {
        height: 46px !important;
        width: 155px;
        font-size: 13px !important;
    }

    .z-navbar-logo {
        height: 30px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 445px;
        min-height: 56vh;
    }

    .z-nav-btn {
        font-size: 12px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 110px;
        height: 29px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 9vh;
        height: 60px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 8% 0 12%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 29px;
        line-height: 37px;
        margin-top: 9%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 132px !important;
        height: 42px !important;
        font-size: 13px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 16% 6% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 16%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 22px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 29px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 9%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 40vh;
        height: 290px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 16px;
    }

    .z-footer-header {
        font-size: 24px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 24px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 35% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 15px !important;
        width: 175px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 96vh;
        height: 670px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 8% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 85% !important;
    }

    .z-section-btn-login {
        width: 185px !important;
    }

    .z-welcome-icon {
        height: 130px;
        margin-top: 2%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }
    .z-b-flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-custom .ant-modal-content {
        min-height: 36vh;
        height: 470px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-login-mt {
        margin-top: 5%;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-signup-hr {
        margin: 1% 0 6% 15%;
    }

    .z-signup-company {
        margin-bottom: 5%;
    }

    .z-mt-18 {
        margin-top: 10%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }

    .z-tab-active {
        width: 185px;
        height: 155px;
        top: -15%;
        left: -14%;
    }

    .z-p-10 {
        padding: 0 7%;
    }

    .z-pan-top {
        margin-top: 19%;
    }

    .z-nav-header-p {
        font-size: 12px;
        margin-left: 3%;
        width: 65%;
    }

    .z-nav-search {
        width: 106px;
        display: none;
    }

    .z-dashboard .ant-layout-header {
        height: 60px;
        padding: 1% 7% 1% 5%;
    }
    .z-dash-res {
        justify-content: flex-end;
    }

    .z-nav-search-image {
        height: 30px;
        width: 53px;
        margin-right: 12%;
    }

    .z-new-tab {
        width: 235px;
        height: 175px;
    }

    .z-new-tab {
        width: 237px;
        height: 165px;
    }

    .z-panel-res {
        height: 350px;
        margin-left: 0 !important;
    }

    .z-dashboard-progress-div {
        height: 185px;
        width: 275px;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 530px;
    }

    .z-res-12 {
        width: 100%;
        margin-bottom: 60px;
    }

    .z-apply-side {
        width: 100%;
        min-height: 30vh;
        margin-left: 0 !important;
    }

    .z-res-10 {
        width: 37%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-custom2 .ant-modal-content {
        min-height: 36vh;
        height: 75vh;
        width: 75% !important;
    }

    .z-custom5 .ant-modal-content {
        min-height: 76vh;
        height: 500px;
        width: 75% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-wallet-flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .z-wallet-100 {
        width: 100%;
    }

    .z-wallet-card {
        width: 65%;
    }
    .z-wallet-none {
        display: none;
    }
    .z-wallet-button {
        display: flex;
        justify-content: center;
        margin: 50px 0 60px;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
}

@media (max-width: 700px) {
    .z-landing-image {
        height: 375px;
        min-height: 49vh;
    }

    .z-landing-div h1 {
        font-size: 28px;
        margin-top: 0;
        margin-bottom: 18px;
        line-height: 34px;
    }
    .z-landing-div {
        margin: 20% 11% 0 23%;
        height: 135px;
        min-height: 12vh;
    }

    .z-landing-btn {
        height: 46px !important;
        width: 155px;
        font-size: 13px !important;
    }

    .z-navbar-logo {
        height: 24px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 340px;
        min-height: 50vh;
    }
    .z-screen-height {
        height: 340px;
        min-height: 50vh;
    }

    .z-nav-btn {
        font-size: 12px;
        margin-left: 5%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 110px;
        height: 29px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 7vh;
        height: 35px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 8% 0 12%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 29px;
        line-height: 37px;
        margin-top: 9%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 22px;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .z-section-btn {
        width: 132px !important;
        height: 42px !important;
        font-size: 13px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 16% 6% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 16%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 22px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 29px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 75px;
        height: 75px;
    }

    .z-testimonial-parag {
        margin: 7% 0 0;
        padding: 0 9%;
        font-size: 15px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 40vh;
        height: 290px;
    }

    .z-scroll {
        width: 6%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 35px;
    }

    .z-footer-terms {
        font-size: 16px;
    }

    .z-footer-header {
        font-size: 24px;
        margin-bottom: 8%;
    }

    .z-footer-social i {
        font-size: 22px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 24px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 35% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 15px !important;
        width: 175px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 96vh;
        height: 670px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 8% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 4%;
    }

    .z-custom {
        width: 100% !important;
    }

    .z-custom .ant-modal-content {
        min-height: 39vh;
        height: 460px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-section-btn-login {
        width: 170px !important;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }
    .z-b-flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-mt-13 {
        margin-top: 11%;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-mb-20 {
        margin-bottom: 14%;
    }

    .z-mt-26 {
        margin-top: 20%;
        /* margin-top: 22%; */
    }

    .z-signup-hr {
        margin: 1% 0 4% 15%;
    }

    .z-mt-18 {
        margin-top: 10%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 38px !important;
        font-size: 13px !important;
    }

    .z-tab-active {
        width: 185px;
        height: 155px;
        top: -15%;
        left: -16%;
    }

    .z-p-10 {
        padding: 0 2%;
    }

    .z-pan-top {
        margin-top: 21%;
    }

    .z-dashboard .ant-layout-content {
        padding: 3% 5% 3% !important;
    }

    .z-dashboard-label {
        margin-bottom: 4%;
    }

    .z-new-tab {
        width: 200px;
        height: 165px;
    }

    .z-panel-res {
        height: 330px;
        margin-left: 0 !important;
    }

    .z-dashboard-progress-div {
        height: 175px;
        width: 260px;
    }
    .z-res-w-70 {
        width: 70%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 530px;
    }

    .z-res-10 {
        width: 45%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-custom2 .ant-modal-content {
        min-height: 39vh;
        height: 75vh;
        width: 75% !important;
    }

    .z-custom5 .ant-modal-content {
        min-height: 76vh;
        height: 500px;
        width: 75% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-wallet-card {
        width: 75%;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 50% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-header-p {
        font-size: 12px;
        margin-left: 3%;
        width: 90%;
    }
}

@media (max-width: 600px) {
    .z-landing-image {
        height: 345px;
        min-height: 37vh;
    }
    .z-landing-div {
        margin: 17% 11% 0 22%;
        height: 125px;
        min-height: 12vh;
    }
    .z-landing-div h1 {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .z-landing-btn {
        height: 44px !important;
        width: 155px;
        font-size: 13px !important;
    }

    .z-navbar-logo {
        height: 24px;
    }

    .z-nav-btn {
        margin-left: 5%;
        padding: 0 7px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 309px;
        min-height: 46vh;
    }
    .z-screen-height {
        height: 309px;
        min-height: 46vh;
    }

    .z-nav-btn {
        font-size: 10px;
        margin-left: 3%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 79px;
        height: 24px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 7vh;
        height: 35px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 6% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 26px;
        line-height: 32px;
        margin-top: 14%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 21px;
        margin-bottom: 6%;
        font-size: 15px;
    }

    .z-section-btn {
        width: 124px !important;
        height: 42px !important;
        font-size: 13px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 16% 4% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 16%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 20px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 26px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 70px;
        height: 70px;
    }

    .z-testimonial-parag {
        margin: 8% 0 0;
        padding: 0 8%;
        font-size: 13px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 39vh;
        height: 260px;
    }

    .z-scroll {
        width: 8%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 28px;
    }

    .z-footer-terms {
        font-size: 14px;
    }

    .z-footer-header {
        font-size: 20px;
        margin-bottom: 7%;
    }

    .z-footer-social i {
        font-size: 18px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 20px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 45% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 14px !important;
        width: 119px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 81vh;
        height: 460px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 8% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 8%;
    }

    .z-welcome-icon {
        height: 120px;
        margin-top: 2%;
    }

    .z-section-btn-login {
        width: 170px !important;
    }

    .z-custom .ant-modal-content {
        min-height: 39vh;
        height: 450px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }

    .z-mt-26 {
        margin-top: 26%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-active {
        width: 185px;
        height: 155px;
        top: -15%;
        left: -16%;
    }

    .z-p-10 {
        padding: 0 2%;
    }

    .z-pan-top {
        margin-top: 27%;
    }

    .z-dashboard-tab {
        width: 125px;
        height: 110px;
    }

    .z-dashboard-tab p {
        font-size: 13px;
        line-height: 17px;
    }

    .z-tab-active {
        width: 167px;
        height: 150px;
        top: -17%;
        left: -16%;
    }

    .z-tab-button {
        height: 30px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 36px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image {
        height: 26px;
        width: 53px;
        margin-right: 0;
    }

    .z-new-tab {
        width: 185px;
        height: 159px;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 530px;
    }

    .z-res-10 {
        width: 52%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-custom2 .ant-modal-content {
        min-height: 43vh;
        height: 75vh;
        width: 99% !important;
    }

    .z-custom5 .ant-modal-content {
        min-height: 78vh;
        height: 500px;
        width: 99% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-wallet-card {
        width: 80%;
        height: 206px;
    }

    .z-profile-width-res {
        width: 100%;
        margin-bottom: 40px !important;
        margin-top: 0 !important;
    }

    .z-profile-card {
        min-height: 1200px;
        width: 90%;
        height: inherit;
    }

    .z-profile-button {
        height: 40px !important;
        width: 100% !important;
        font-size: 13px !important;
    }

    .z-profile-avatar {
        width: 90px;
        height: 90px;
        border: 3px solid #fff;
        margin: auto auto 45px;
        position: relative;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 55% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
}

@media (max-width: 560px) {
    .z-landing-image {
        height: 312px;
        min-height: 44vh;
    }
    .z-landing-div {
        margin: 14% 11% 0 22%;
        height: 125px;
        min-height: 12vh;
    }

    .z-landing-div h1 {
        font-size: 23px;
        margin-top: 0;
        margin-bottom: 15px;
        line-height: 28px;
    }
    .z-nav-btn {
        font-size: 12px;
    }
    .z-divl-height {
        height: 125px;
        min-height: 12vh;
    }
    .z-screen-height {
        height: 285px;
        min-height: 35vh;
    }

    .z-nav-btn {
        font-size: 10px;
        margin-left: 3%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 79px;
        height: 24px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 7vh;
        height: 35px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 6% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 26px;
        line-height: 32px;
        margin-top: 14%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 5%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 21px;
        margin-bottom: 6%;
        font-size: 15px;
    }

    .z-section-btn {
        width: 124px !important;
        height: 42px !important;
        font-size: 13px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 16% 4% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 16%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 20px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 26px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 6%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 70px;
        height: 70px;
    }

    .z-testimonial-parag {
        margin: 8% 0 0;
        padding: 0 8%;
        font-size: 13px;
    }

    .z-testimonial-name {
        font-size: 19px;
    }

    .z-testimonial-card {
        min-height: 39vh;
        height: 260px;
    }

    .z-scroll {
        width: 8%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 28px;
    }

    .z-footer-terms {
        font-size: 14px;
    }

    .z-footer-header {
        font-size: 20px;
        margin-bottom: 7%;
    }

    .z-footer-social i {
        font-size: 18px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 20px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 45% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 14px !important;
        width: 119px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 81vh;
        height: 460px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }
    .z-padding-testimonial {
        margin: 8% 0 1%;
    }

    .z-section-scroll-div {
        margin-top: 8%;
    }

    .z-section-btn-login {
        width: 170px !important;
    }

    .z-welcome-icon {
        height: 125px;
        margin-top: 2%;
    }

    .z-custom .ant-modal-content {
        min-height: 43vh;
        height: 440px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 80vh;
    }
    .z-login-res {
        margin-left: 25%;
        width: 75%;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }
    .z-b-flex {
        width: 100%;
        margin-top: 18%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-mt-13 {
        margin-top: 13%;
    }

    .z-signup-small {
        height: 42px !important;
        font-size: 14px !important;
    }
    .z-signup-res {
        margin-left: 20%;
        width: 16%;
    }
    .z-signup-res2 {
        width: 62%;
    }

    .z-mt-26 {
        margin-top: 20%;
    }

    .z-mt-18 {
        margin-top: 8%;
    }

    .z-signup-hr {
        margin: 1% 0 3% 15%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    .z-tab-active {
        width: 185px;
        height: 155px;
        top: -15%;
        left: -16%;
    }

    .z-p-10 {
        padding: 0 2%;
    }

    .z-pan-top {
        margin-top: 27%;
    }

    .z-dashboard-tab {
        width: 125px;
        height: 110px;
    }

    .z-dashboard-tab p {
        font-size: 13px;
        line-height: 17px;
    }

    .z-tab-active {
        width: 167px;
        height: 150px;
        top: -17%;
        left: -16%;
    }

    .z-tab-button {
        height: 30px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 36px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image {
        height: 26px;
        width: 53px;
        margin-right: 12%;
    }

    .z-nav-header h2 {
        font-size: 21px;
    }

    .z-tab-active {
        width: 146px;
        height: 139px;
        top: -14%;
        left: -16%;
    }

    .z-tab-button {
        height: 30px !important;
        font-size: 12px !important;
        width: 68px !important;
    }

    .z-tab-button-active {
        height: 36px !important;
        font-size: 13px !important;
        width: 95px !important;
    }
    .z-res-direc {
        flex-direction: column;
    }

    .z-panel-res {
        height: 500px;
        margin-left: 0 !important;
    }

    .z-new-tab {
        width: 255px;
        height: 175px;
        margin-bottom: 20px;
    }

    .z-dashboard-progress-div {
        height: 175px;
        width: 260px;
        margin-bottom: 25px;
    }
    .z-res-no-ml {
        margin-left: 0;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 530px;
    }

    .z-res-10 {
        width: 52%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-apply-label {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .z-apply-label-small {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .z-custom2 .ant-modal-content {
        min-height: 43vh;
        height: 75vh;
        width: 99% !important;
    }

    .z-img-100 {
        width: 110px;
        height: 110px;
    }

    .z-wallet-card {
        width: 90%;
        height: 206px;
    }

    .z-profile-button {
        height: 40px !important;
        width: 100% !important;
        font-size: 13px !important;
    }

    .z-profile-avatar {
        width: 90px;
        height: 90px;
        border: 3px solid #fff;
        margin: auto auto 45px;
        position: relative;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 55% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }
}

@media (max-width: 500px) {
    .z-landing-image {
        height: 254px;
        min-height: 30vh;
    }
    .z-landing-div {
        margin: 9% 11% 0 22%;
        height: 125px;
        min-height: 12vh;
    }

    .z-landing-div h1 {
        font-size: 19px;
        margin-top: 0;
        margin-bottom: 15px;
        line-height: 24px;
    }

    .z-landing-btn {
        height: 40px !important;
        width: 130px;
        font-size: 12px !important;
    }
    .z-divl-height {
        height: 125px;
        min-height: 12vh;
    }
    .z-screen-height {
        height: 210px;
        min-height: 12vh;
    }

    .z-nav-btn {
        font-size: 10px;
        margin-left: 3%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 79px;
        height: 24px;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 7vh;
        height: 35px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 4% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 22px;
        line-height: 28px;
        margin-top: 14%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 8%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 21px;
        margin-bottom: 6%;
        font-size: 14px;
    }

    .z-section-btn {
        width: 140px !important;
        height: 43px !important;
        font-size: 12px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 20% 4% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 20%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 20px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 22px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 3%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 70px;
        height: 70px;
    }

    .z-testimonial-parag {
        margin: 6% 0 0;
        padding: 0 8%;
        font-size: 13px;
    }

    .z-testimonial-name {
        font-size: 18px;
    }

    .z-testimonial-card {
        min-height: 32vh;
        height: 210px;
    }

    .z-scroll {
        width: 11%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 28px;
    }

    .z-footer-terms {
        font-size: 14px;
    }

    .z-footer-header {
        font-size: 20px;
        margin-bottom: 7%;
    }

    .z-footer-social i {
        font-size: 18px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 20px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 45% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 14px !important;
        width: 119px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 78vh;
        height: 460px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }

    .z-section-btn-login {
        width: 170px !important;
    }

    .z-welcome-icon {
        height: 120px;
        margin-top: 3%;
    }

    .z-custom .ant-modal-content {
        min-height: 43vh;
        height: 435px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 90vh;
        height: 700px;
    }

    /*.z-welcome-icon {
        height: 115px;
        margin-top: 3%;
    }*/
    .z-b-flex {
        width: 100%;
        margin-top: 16%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }
    .z-signup-res {
        margin-left: 6%;
        width: 16%;
    }
    .z-signup-res2 {
        width: 76%;
    }

    .z-signup-header {
        text-align: center;
        font-size: 19px !important;
        color: #525d7c !important;
        line-height: 36px !important;
    }

    .z-signup-parag {
        /* width: 63%; */
        padding: 0 18%;
        font-size: 14px;
    }

    .z-mb-20 {
        margin-bottom: 12%;
    }

    .z-mt-26 {
        margin-top: 24%;
    }

    .z-signup-small {
        height: 42px !important;
    }

    /*.z-signup-company {
        height: 40px !important;
        width: 85%;
    }*/
    .z-login-res {
        margin-left: 15%;
        width: 85%;
    }

    .z-mt-13 {
        margin-top: 18%;
    }

    .z-signup-hr {
        margin: 1% 0 1% 15%;
    }

    .z-mt-18 {
        margin-top: 8%;
    }

    .z-dashboard .ant-layout-sider-collapsed {
        display: none;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-tab-button {
        height: 32px !important;
        font-size: 13px !important;
    }

    /*.z-tab-active {
        width: 185px;
        height: 155px;
        top: -15%;
        left: -16%;
    }*/
    .z-p-10 {
        padding: 0 2%;
    }

    .z-dashboard-tab {
        width: 125px;
        height: 105px;
    }

    .z-dashboard-tab p {
        font-size: 13px;
        line-height: 17px;
    }

    .z-tab-button {
        height: 30px !important;
        font-size: 13px !important;
    }

    .z-tab-button-active {
        height: 36px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image {
        height: 26px;
        width: 53px;
        margin-right: 12%;
    }

    .z-nav-header h2 {
        font-size: 21px;
    }

    .z-tab-active {
        width: 185px;
        height: 145px;
        top: -14%;
        left: -25%;
    }

    .z-tab-button {
        height: 24px !important;
        font-size: 12px !important;
        width: 68px !important;
    }

    .z-tab-button-active {
        height: 36px !important;
        font-size: 13px !important;
        width: 95px !important;
    }

    .z-nav-header-p {
        font-size: 12px;
        margin-left: 0%;
        width: 90%;
    }

    .z-dash-res {
        display: none;
    }

    .z-nav-search-image {
        height: 38px;
        width: 60px;
        margin-right: 0;
    }

    .z-dashboard-panel {
        height: 520px;
    }

    .z-pan-top {
        margin-top: 20%;
    }

    .z-panel-res {
        height: 500px;
        margin-left: 0 !important;
    }

    .z-new-tab {
        width: 255px;
        height: 175px;
        margin-bottom: 20px;
        padding: 7% 7% 8% 9%;
    }

    .z-dashboard-progress-div {
        height: 175px;
        width: 260px;
        margin-bottom: 25px;
    }
    .z-res-no-ml {
        margin-left: 0;
    }

    .z-new-amount {
        font-size: 34px !important;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }
    .z-pamel-rev {
        flex-direction: column;
        height: 360px;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 500px;
    }

    .z-res-10 {
        width: 60%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-apply-label {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .z-dash-apply {
        min-height: 75vh;
        /* height: 1100px; */
    }

    .z-width-44 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .z-apply-card {
        margin-bottom: 25px;
    }

    .z-apply-label-small {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 23px;
    }

    .z-mt-7 {
        margin-top: 0;
    }
    .z-apply-select {
        width: 100% !important;
    }

    .z-guarantor-padding2 {
        padding: 0px 5%;
    }

    .z-gr-div {
        width: 40%;
    }

    .z-guarantor-panel {
        max-height: 410px;
        min-height: 380px;
    }

    .z-guarantor-padding {
        padding: 5% 7%;
    }

    .z-guarantor-padding3 {
        padding: 0 4% 0 3%;
    }

    .z-gres-small {
        display: none;
    }

    .z-img-100 {
        width: 75px;
        height: 75px;
        margin-bottom: 8px;
    }

    .z-custom2 .ant-modal-content {
        min-height: 43vh;
        height: 75vh;
    }
    .z-cup-res-div .ant-modal-body {
        height: 291px;
        overflow: scroll;
    }
    .z-cup-res {
        flex-direction: column;
        justify-content: flex-start;
    }

    .z-width-60 {
        width: 96%;
    }

    .z-file-div {
        width: 100%;
    }

    .z-card-debit2 .z-chip {
        height: 42px;
        margin-top: 15px;
        margin-left: 18px;
    }

    .z-wallet-btn {
        font-size: 14px !important;
        width: 175px !important;
        margin: 0 !important;
    }

    .z-profile-width {
        width: 100%;
        margin-right: 4%;
    }
    .z-wall .ant-modal-body {
        overflow-y: scroll;
        height: 254px;
        overflow-x: hidden;
    }

    .z-profile-button {
        height: 40px !important;
        width: 100% !important;
        font-size: 14px !important;
    }

    .z-profile-avatar {
        width: 90px;
        height: 90px;
        border: 3px solid #fff;
        margin: auto auto 45px;
        position: relative;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 77% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image-more {
        left: unset;
        right: -28px;
        top: 40px;
        width: 365px;
    }

    .z-nav-search-image-more.z-more-top::before {
        left: 82%;
    }

    .z-select-card .ant-modal-content {
        height: 85vh;
    }
}

@media (max-width: 400px) {
    .z-landing-image {
        height: 220px;
        min-height: 28vh;
    }

    .z-navbar {
        font-size: 13px;
        padding: 2% 4% 2% 4%;
        min-height: 5vh;
        height: 31px;
    }

    .z-landing-div {
        margin: 13% 2% 0 18%;
        height: 125px;
        min-height: 12vh;
    }

    .z-landing-div h1 {
        font-size: 19px;
        margin-top: 0;
        margin-bottom: 20px;
        line-height: 24px;
    }

    /*.z-landing-btn {
        height: 38px !important;
        width: 113px;
        font-size: 12px !important;
    }*/
    .z-landing-btn {
        height: 39px !important;
        width: 140px;
        font-size: 11px !important;
    }
    .z-divl-height {
        height: 125px;
        min-height: 12vh;
    }
    .z-screen-height {
        height: 210px;
        min-height: 12vh;
    }

    .z-nav-btn {
        font-size: 10px;
        margin-left: 3%;
        padding: 0 6px;
    }

    .z-nav-login {
        width: 79px;
        height: 24px;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 4% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 15%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 8%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 21px;
        margin-bottom: 6%;
        font-size: 13px;
    }

    .z-section-btn {
        width: 110px !important;
        height: 40px !important;
        font-size: 12px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 23% 4% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 23%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 20px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 20px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 3%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 70px;
        height: 70px;
    }

    .z-testimonial-parag {
        margin: 6% 0 0;
        padding: 0 8%;
        font-size: 13px;
    }

    .z-testimonial-name {
        font-size: 18px;
    }

    .z-testimonial-card {
        min-height: 32vh;
        height: 210px;
    }

    .z-scroll {
        width: 11%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 28px;
    }

    .z-footer-terms {
        font-size: 14px;
    }

    .z-footer-header {
        font-size: 20px;
        margin-bottom: 7%;
    }

    .z-footer-social i {
        font-size: 18px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 20px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 45% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 14px !important;
        width: 119px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 78vh;
        height: 460px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }
    .z-footer-bm {
        margin-bottom: 12%;
    }

    .z-section-btn-login {
        width: 170px !important;
    }

    .z-welcome-icon {
        height: 115px;
        margin-top: 2%;
    }

    .z-custom .ant-modal-content {
        min-height: 42vh;
        height: 410px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 90vh;
        height: 700px;
    }

    .z-login-res {
        margin-left: 0;
        width: 100%;
    }
    .z-login-input,
    .z-login-input2 input {
        height: 40px !important;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 85% !important;
    }

    .z-signup-hr {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 70%;
        margin: 0% 0 2% 15%;
    }

    .z-login-active {
        font-size: 20px !important;
    }

    .z-b-flex {
        width: 100%;
        margin-top: 15%;
    }

    .z-p-small {
        color: #525d7c;
        font-size: 13px;
    }

    .z-signup-res {
        margin-left: 0;
        width: 16%;
    }

    .z-signup-res2 {
        width: 84%;
    }

    .z-mb-20 {
        margin-bottom: 11%;
    }

    .z-mt-26 {
        margin-top: 20%;
    }

    .z-signup-header {
        line-height: 30px !important;
    }
    .ant-select-lg .ant-select-selection--single {
        height: 36px !important;
    }

    /* .z-signup-company {
        height: 40px !important;
        width: 85%;
    }*/
    .z-mt-18 {
        margin-top: 8%;
    }

    .z-dashboard-avatar {
        width: 40px;
        height: 40px;
    }

    .z-p-10 {
        padding: 0 0%;
    }

    .z-landing-btn {
        height: 24px !important;
        width: 140px;
        font-size: 11px !important;
    }

    .z-tab-active {
        width: 170px;
        height: 140px;
        top: -10%;
        left: -9%;
    }

    .z-tab-active p {
        font-size: 16px !important;
        line-height: 16px !important;
    }

    .z-tab-button-active {
        height: 33px !important;
        font-size: 13px !important;
        width: 95px !important;
    }

    .z-dashboard-tab p {
        font-size: 13px;
        line-height: 15px;
    }

    .z-dashboard-panel {
        height: 500px;
    }

    .z-pan-top {
        margin-top: 20%;
    }

    .z-dashboard-tab {
        width: 140px;
        height: 110px;
    }

    .z-nav-search-image {
        height: 38px;
        width: 53px;
        margin-right: 0;
    }

    .z-panel-res {
        height: 500px;
        margin-left: 0 !important;
    }
    .z-pamel-rev {
        flex-direction: column;
        height: 360px;
    }

    .z-new-tab {
        width: 255px;
        height: 175px;
        margin-bottom: 20px;
        padding: 7% 7% 8% 9%;
    }

    .z-dashboard-progress-div {
        height: 175px;
        width: 260px;
        margin-bottom: 25px;
    }
    .z-res-no-ml {
        margin-left: 0;
    }

    .z-new-amount {
        font-size: 34px !important;
    }
    .z-guarantor-panel {
        max-height: 135vh;
        height: 100%;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 475px;
        border-radius: 35px;
    }

    .z-gr-p {
        padding: 0 4%;
    }

    .z-loan-padding2 {
        padding: 0 4%;
    }

    .z-mb-2 {
        margin-bottom: 3% !important;
    }

    .z-modal-label-div {
        padding: 0 5%;
    }

    .z-loan-graph {
        min-height: 120px;
    }

    .z-section-btn {
        height: 40px !important;
    }
    .z-app-modal .ant-modal-body,
    .z-custom3 .ant-modal-body {
        height: 327px;
    }

    .z-res-10 {
        width: 69%;
    }

    .z-btn-medium {
        height: 42px !important;
        width: 161px !important;
    }

    .z-dash-apply {
        min-height: 75vh;
        /* height: 1100px; */
    }

    .z-custom3 .ant-modal-content {
        min-height: 445px;
        border-radius: 35px;
    }

    .z-gr-div {
        width: 45%;
    }

    .z-loan-padding {
        padding: 0 2% 0 2%;
    }
    .z-pres-small {
        width: 70%;
    }
    .z-width-30 {
        display: none;
    }

    .z-custom2 .ant-modal-content {
        min-height: 33vh;
        height: 75vh;
    }

    .z-modal-nav {
        width: 43%;
        left: 29%;
    }

    .z-testimonial-avatar {
        width: 60px;
        height: 60px;
    }

    .z-width-60 {
        width: 100%;
    }

    .z-cup-res-div .ant-modal-body {
        padding: 21px;
        height: 290px;
    }

    .z-modal-header {
        font-size: 21px;
    }

    .z-custom2 .ant-modal-footer {
        min-height: 8vh;
        height: 70px;
    }

    .z-custom2 .ant-modal-header {
        min-height: 8vh;
        height: 71px;
    }

    .z-request-card {
        margin-bottom: 0;
    }

    .z-request--small-p {
        margin-top: 8px;
    }

    .z-todo-div p {
        line-height: 23px;
    }

    .z-wallet-card {
        width: 95%;
        height: 191px;
    }

    .z-wallet-input {
        height: 100px !important;
        font-size: 46px !important;
    }

    .z-wallet-card-tag {
        font-size: 16px;
    }

    .z-wallet-btn {
        font-size: 14px !important;
        width: 175px !important;
        margin: 0 !important;
    }

    .z-profile-width {
        width: 100%;
        margin-right: 4%;
    }
    .z-wall .ant-modal-body {
        overflow-y: scroll;
        height: 254px;
        overflow-x: hidden;
    }

    .z-w-modal {
        padding: 0 5%;
    }

    .z-profile-button {
        height: 40px !important;
        width: 100% !important;
        font-size: 14px !important;
    }

    .z-profile-avatar {
        width: 90px;
        height: 90px;
        border: 3px solid #fff;
        margin: auto auto 45px;
        position: relative;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 87% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image-more {
        width: 345px;
        right: -20px;
    }
}

@media (max-width: 360px) {
    .z-navbar-logo {
        height: 17px;
    }

    .z-landing-image {
        height: 198px;
        min-height: 24vh;
    }

    .z-landing-div h1 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 14px;
        line-height: 21px;
    }

    .z-landing-div {
        margin: 10% 2% 0 18%;
        height: 125px;
        min-height: 12vh;
    }

    .z-section-padding {
        /* margin: 0 4% 0 14%; */
        margin: 0 4% 0 10%;
    }

    .z-section-div {
        padding-left: 0;
        width: 100%;
    }

    .z-section-div h2 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 15%;
    }

    .z-section-image {
        min-height: 19vh;
        height: inherit;
        /*background-image: none;*/
    }

    .z-section-div hr {
        border-bottom: 5px solid #999;
        width: 8%;
        margin: 3% 0 6%;
    }

    .z-section-div p {
        color: #a5a5a5;
        font-family: CircularStd-Book;
        font-weight: lighter;
        padding-right: 0;
        line-height: 21px;
        margin-bottom: 6%;
        font-size: 13px;
    }

    .z-section-btn {
        width: 110px !important;
        height: 38px !important;
        font-size: 12px !important;
    }

    .z-img-overlay-bgg {
        width: 100%;
        background: #ffffffe0;
        margin-left: 0;
    }

    .z-section-image-right {
        height: inherit;
    }

    .z-section-padding2 {
        margin: 23% 4% 0 10%;
    }

    .z-sec-res {
        margin-top: 0 !important;
    }

    .z-sec-res-hr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .z-sec-res-p {
        text-align: right;
    }

    .z-section-image3 {
        height: inherit;
    }

    .z-res-margin {
        margin-top: 23%;
    }

    .z-section-image-right-in {
        min-height: 50vh;
        height: 335px;
        width: 50%;
    }

    .z-section-image-left-in {
        min-height: 52vh;
        height: 340px;
        width: 50%;
        margin-top: 5%;
    }

    .z-section-scroll-div i {
        font-size: 20px;
    }

    .z-res-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .z-res-h2 {
        padding-left: 10%;
        margin-bottom: 7% !important;
        line-height: 28px;
    }

    .z-testimonial h2 {
        font-size: 20px;
        line-height: 48px;
        margin-bottom: 5%;
    }

    .z-scroll-top {
        margin-top: 3%;
    }

    .z-test-res {
        margin: 0 7%;
        width: 86%;
    }

    .z-testimonial-avatar {
        width: 70px;
        height: 70px;
    }

    .z-testimonial-parag {
        margin: 8% 0 0;
        padding: 0 8%;
        font-size: 13px;
    }

    .z-testimonial-name {
        font-size: 18px;
    }

    .z-testimonial-card {
        min-height: 32vh;
        height: 210px;
    }

    .z-scroll {
        width: 11%;
    }

    .z-scroll-active {
        height: 8px;
        width: 8px;
    }

    .z-scroll-div {
        height: 8px;
        width: 8px;
    }

    .z-flex-space2 {
        flex-direction: column;
    }

    .z-res-navbar-logo {
        height: 28px;
    }

    .z-footer-terms {
        font-size: 14px;
    }

    .z-footer-header {
        font-size: 20px;
        margin-bottom: 7%;
    }

    .z-footer-social i {
        font-size: 18px;
        margin-right: 13%;
    }

    .z-footer-res-margin {
        margin-bottom: 10%;
    }

    .z-footer-subscribe p {
        font-size: 20px;
    }

    .z-footer-input {
        height: 40px;
        font-size: 13px;
        padding: 6px 45% 6px 11px;
    }

    .z-footer-button {
        height: 40px !important;
        padding: 0 !important;
        font-size: 14px !important;
        width: 119px;
    }
    .z-footer-res {
        width: 100%;
        margin: 0;
    }

    .z-home-footer {
        /*background-image: none;*/
        min-height: 78vh;
        height: 460px;
        background: #f7faff;
        border-bottom-right-radius: 70px;
    }

    .z-custom {
        width: 100% !important;
    }

    .z-welcome-icon {
        height: 100px;
        margin-top: 3%;
    }

    .z-custom .ant-modal-content {
        min-height: 33vh;
        height: 390px;
    }

    .z-custom5 .ant-modal-content {
        min-height: 90vh;
        height: 700px;
    }

    .z-section-btn-login {
        width: 150px !important;
    }

    .z-login-btn {
        font-size: 14px !important;
        width: 95% !important;
    }

    .z-signup-parag {
        /* width: 63%; */
        padding: 0 18%;
        font-size: 13px;
        line-height: 13px;
        margin-top: 5%;
        /* letter-spacing: -1px; */
    }

    .z-mt-26 {
        margin-top: 20%;
    }

    .z-signup-header {
        text-align: center;
        font-size: 18px !important;
    }

    /*.z-signup-company {
        height: 40px !important;
        width: 85%;
    }*/
    .z-signup-res2 {
        width: 100%;
    }

    .z-signup-res {
        margin-left: 0;
        width: 16%;
        display: none;
    }

    .z-mb-20 {
        margin-bottom: 9%;
    }

    .z-signup-hr {
        margin: 0% 0 3% 15%;
    }

    .z-signup-company {
        margin-bottom: 3%;
        width: 95%;
    }

    .z-mt-18 {
        margin-top: 8%;
    }

    .z-signup-password {
        width: 95%;
    }

    .z-login-mt {
        margin-top: 10%;
    }

    .z-dashboard-tab {
        /* width: 86px;
        height: 94px;
        border-radius: 29px;*/
    }

    .z-tab-active {
        /* width: 112px;
        height: 112px;
        top: -10%;
        left: -14%;*/
    }

    .z-tab-active p {
        font-size: 14px !important;
        line-height: 14px !important;
    }

    .z-tab-button-active {
        height: 32px !important;
        font-size: 13px !important;
        width: 83px !important;
    }

    .z-nav-search-image {
        height: 38px;
        width: 47px;
        margin-right: 0;
    }

    .z-new-tab {
        width: 230px;
    }

    .z-dashboard-progress-div {
        width: 245px;
    }

    .z-custom3 {
        width: 522px !important;
    }

    .z-package-p {
        width: 100%;
        padding: 6% 8%;
    }

    .z-custom3 .ant-modal-content {
        min-height: 436px;
        border-radius: 35px;
    }
    .z-app-modal .ant-modal-body,
    .z-custom3 .ant-modal-body {
        height: 280px;
    }

    .z-res-10 {
        width: 85%;
    }

    .z-gr-div {
        width: 50%;
    }
    .z-width-30 {
        display: none;
    }

    .z-loan-padding {
        padding: 0 2% 0 4%;
    }
    .z-pres-small span {
        display: none;
    }

    .z-pres-small {
        width: 54%;
    }

    .z-guarantor-padding2 {
        padding: 0px 3%;
    }

    .z-testimonial-avatar {
        width: 60px;
        height: 60px;
    }

    .z-custom2 .ant-modal-content {
        min-height: 85vh;
    }

    .z-padding-15 {
        padding: 2% 2%;
    }

    .z-card-debit2 {
        height: 135px;
        width: 250px;
    }

    .z-card-debit2 .z-chip {
        height: 29px;
        width: 66px;
        margin-top: 12px;
        margin-left: 0;
    }

    .z-card-debit2 .z-modal-parag-small2 {
        font-size: 14px !important;
    }

    .z-card-arrowl {
        position: absolute;
        top: 56%;
        left: 9%;
        font-size: 16px;
    }

    .z-card-arrowR {
        position: absolute;
        top: 56%;
        right: 9%;
        font-size: 16px;
    }

    .z-card-label-small {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .z-textp {
        padding: 0% 16% 0;
    }

    .z-icon-modal2 {
        margin: 2% auto 5%;
    }

    .z-icon-modal {
        height: 90px;
        width: 300px;
    }

    .z-modal-parag {
        padding: 0 2px;
    }

    .z-navp {
        display: none;
    }
    .z-navp-bt {
        margin-bottom: 10px !important;
        margin-top: 3px;
    }
    .z-notification-dIcon i {
        font-size: 11px;
    }

    .z-notification-dIcon {
        height: 23px;
        width: 27px;
    }
    .z-todo-div p {
        width: 100%;
    }

    .z-wallet-btn {
        font-size: 14px !important;
        width: 175px !important;
        margin: 0 !important;
    }

    .z-wallet-card {
        width: 95%;
        height: 182px;
    }

    .z-wallet-input {
        height: 98px !important;
        font-size: 42px !important;
    }

    .z-profile-button {
        height: 40px !important;
        width: 100% !important;
        font-size: 14px !important;
    }

    .z-profile-avatar {
        width: 80px;
        height: 80px;
        border: 3px solid #fff;
        margin: auto auto 45px;
        position: relative;
    }

    .z-log-modal .ant-modal-content {
        min-height: 5vh;
        height: 220px;
        width: 95% !important;
    }

    .z-modal-btn {
        height: 35px !important;
        width: 75px !important;
        font-size: 13px !important;
    }

    .z-nav-search-image-more {
        width: 310px;
        right: -18px;
    }
}

@media (max-width: 355px) {
}
