@import "~antd/dist/antd.css";
@import url("https://fonts.google.com/specimen/Maven+Pro?selection.family=Maven+Pro");

@font-face {
    font-family: CircularStd-Black;
    src: url("../../assets/font/circular-std-book-cufonfonts/CircularStd-Black.otf")
        format("opentype");
}

@font-face {
    font-family: CircularStd-Book;
    src: url("../../assets/font/circular-std-book-cufonfonts/CircularStd-Book.otf")
        format("opentype");
}

@font-face {
    font-family: CircularStd-Mediun;
    src: url("../../assets/font/circular-std-book-cufonfonts/CircularStd-Medium.otf")
        format("opentype");
}

@font-face {
    font-family: ProductSans-Regular;
    src: url("../font/product-sans/ProductSans-Regular.ttf") format("opentype");
}

@font-face {
    font-family: ProductSans-Light;
    src: url("../font/product-sans/ProductSans-Light.ttf") format("opentype");
}

@font-face {
    font-family: ProductSans-Medium;
    src: url("../font/product-sans/ProductSans-Medium.ttf") format("opentype");
}

@font-face {
    font-family: ProductSans-Bold;
    src: url("../font/product-sans/ProductSans-Bold.ttf") format("opentype");
}

@font-face {
    font-family: Roboto-Thin;
    src: url("../../assets/font/roboto/Roboto-Thin.ttf") format("opentype");
}

@font-face {
    font-family: Roboto-Light;
    src: url("../../assets/font/roboto/Roboto-Light.ttf") format("opentype");
}

@font-face {
    font-family: Roboto-Regular;
    src: url("../../assets/font/roboto/Roboto-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Raleway-Regular;
    src: url("../../assets/font/raleway/Raleway-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Raleway-Medium;
    src: url("../../assets/font/raleway/Raleway-Medium.ttf") format("opentype");
}

@font-face {
    font-family: Raleway-Light;
    src: url("../../assets/font/raleway/Raleway-Light.ttf") format("opentype");
}

@font-face {
    font-family: Raleway-SemiBold;
    src: url("../../assets/font/raleway/Raleway-SemiBold.ttf")
        format("opentype");
}

@font-face {
    font-family: MollenPersonalUse-Bold;
    src: url("../../assets/font/MollenPersonalUse/MollenPersonalUse-Bold.otf")
        format("opentype");
}

@font-face {
    font-family: MollenPersonalUse-Light;
    src: url("../../assets/font/MollenPersonalUse/MollenPersonalUse-Light.otf")
        format("opentype");
}

@font-face {
    font-family: MollenPersonalUse-Regular;
    src: url("../../assets/font/MollenPersonalUse/MollenPersonalUse-Regular.otf")
        format("opentype");
}

@font-face {
    font-family: MollenPersonalUse-Narrow;
    src: url("../../assets/font/MollenPersonalUse/MollenPersonalUse-Narrow.otf")
        format("opentype");
}

.z-landing-image {
    background-image: url("../../assets/images/landing.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    min-height: 99vh;
    height: 845px;
}

.z-getting_help-image {
    background-image: url("../../assets/images/getting-help/background.svg");
    background-size: 1800px;
    background-position: -400px -600px;
    min-height: 99vh;
    height: 750px;
}

.z-getting_help-image .z-nav-btn {
    color: #2c2d58;
}

.z-getting_help-image .z-nav-btn:hover {
    border-radius: 25px;
    border: 2px solid #2c2d5826;
}

.z-getting_help-image .z-nav-btn:focus {
    border-radius: 25px;
    border: 2px solid #2c2d5826;
}

.z-navbar {
    height: 10vh;
    padding: 20px 9%;
    /*padding: 20px 135px;*/
}

.z-navbar-logo {
    height: 40px;
}

.z-navbar-logo img {
    height: inherit;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}

.z-flex-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.z-flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.z-nav-btn {
    font-family: ProductSans-Regular, sans-serif;
    font-size: 14px;
    background: no-repeat;
    color: #fff;
    margin-left: 4%;
    border: 0;
}

.z-nav-login {
    border: 1px solid #fff;
    padding: 0;
    width: 150px;
    border-radius: 27px;
}

.z-flex {
    display: flex;
    align-items: center;
}

.z-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-flex-s {
    display: flex;
    justify-content: space-between;
}

.z-nav-btn:hover,
.z-nav-btn:focus {
    color: #2a5a82;
    background: #ffffffa8;
    border-radius: 15px;
    border-color: #f8f8f866;
}

.z-landing-div {
    height: 80vh;
    margin: 0 16% 0 23%;
}

.z-flex-left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.z-landing-div h1 {
    color: #2c2c59;
    font-family: ProductSans-Medium, sans-serif;
    font-size: 60px;
    line-height: 67px;
    margin-bottom: 60px;
    margin-top: 15%;
}

.z-landing-btn {
    height: 60px !important;
    width: 230px;
    padding: 0 20px;
    font-size: 16px !important;
    border-radius: 40px;
    font-family: ProductSans-Medium, sans-serif;
    background: #373d56;
    color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease 0s;
}

.z-landing-btn:hover {
    transform: translateY(-7px);
}

.z-section-padding {
    /* margin: 0 4% 0 14%;*/
    margin: 0 4% 0 13%;
}

.z-section-image {
    background-image: url("../../assets/images/image1.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    min-height: 58vh;
    height: 565px;
}

.z-section-div {
    padding-left: 6%;
}

.z-section-div2 {
    padding-left: 8%;
    padding-right: 4%;
}

.z-section-div h2 {
    color: #2c2c59;
    font-family: ProductSans-Medium;
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 0;
    margin-top: 28%;
    letter-spacing: -1px;
}

.z-section-div hr {
    border: 0;
    border-bottom: 9px solid #999;
    width: 5%;
    margin: 3% 0 6%;
    border-radius: 15px;
}

.z-section-div p {
    color: #a5a5a5;
    font-family: CircularStd-Book;
    font-weight: lighter;
    padding-right: 20%;
    line-height: 21px;
    margin-bottom: 6%;
    font-size: 16px;
}

.z-section-btn {
    height: 48px !important;
    width: 180px !important;
    font-size: 14px !important;
}

.z-section-image-right {
    background-image: url("../../assets/images/image2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    min-height: 75vh;
    height: 707px;
}

.z-section-padding2 {
    margin: 0 5% 0 2%;
}

.z-mt-40 {
    margin-top: 42% !important;
}

.z-mt-5 {
    margin-top: 5%;
}

.z-mt-7 {
    margin-top: 7%;
}

.z-mt-43 {
    margin-top: 35% !important;
}

.z-section-image3 {
    background-image: url("../../assets/images/image 3.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    min-height: 65vh;
    height: 600px;
}

.z-section-image-left-in {
    background-image: url("../../assets/images/left in.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height: 100vh;
    width: 50%;
    margin-top: 5%;
}

.z-section-image-right-in {
    background-image: url("../../assets/images/right in.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    height: 100vh;
    width: 50%;
}

.z-section-scroll-div {
    position: absolute;
    left: 22%;
    right: 21%;
    /* top: 2%; */
    height: 30vh;
    margin-top: 10%;
}

.z-section-scroll-div i {
    font-size: 36px;
    box-shadow: 1px 12px 16px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: pointer;
}

.z-no-padding {
    padding: 0;
}

.z-width-100 {
    width: 100%;
}

.z-margin-btn {
    margin-bottom: 10% !important;
}

.z-section-scroll-div i:hover {
    transform: translateY(-7px);
}

.z-home-footer {
    background-image: url("../../assets/images/footer.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    height: 100vh;
}

.z-testimonial-card {
    min-height: 44vh;
    background: white;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.1);
    padding: 4% 5%;
    margin-bottom: 5%;
}

.z-padding-testimonial {
    margin: 0 0 1%;
}

.z-testimonial h2 {
    color: #2c2c59;
    font-family: ProductSans-Medium;
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 5%;
    text-align: center;
}

.z-testimonial-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto;
    border: 1px solid #dadada;
}

.z-testimonial-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.z-unisex-avatar img {
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.z-testimonial-parag {
    margin: 7% 0 0;
    text-align: center;
    font-family: Raleway-Regular;
    padding: 0 21%;
    font-size: 18px;
}

.z-testimonial-name {
    font-family: ProductSans-Medium;
    margin: 5% 0 0;
    font-size: 20px;
}

.z-scroll-div {
    height: 11px;
    width: 11px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #939598;
}

.z-scroll-active {
    height: 11px;
    width: 11px;
    background: #c5dffb;
    border-radius: 50%;
}

.z-scroll {
    width: 6%;
    margin: auto;
}

.z-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #dcdcdc !important;
    border-radius: 0 !important;
}

.z-scroll-bar::-webkit-scrollbar {
    width: 8px !important;
    background-color: #f3f3f3 !important;
}

.z-height-100 {
    height: 100%;
}

.z-height-100_ {
    height: 100vh;
}

.z-height-100px {
    height: 100px;
}

.z-footer-header {
    color: #3a3a4c;
    font-size: 26px;
    font-family: Roboto-Light;
}

.z-flex-column {
    display: flex;
    flex-direction: column;
}

.z-flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.z-footer-terms {
    font-family: ProductSans-Regular;
    font-size: 19px;
    /* text-decoration: underline; */
    margin-bottom: 10%;
    border: 0;
    width: fit-content;
    border-bottom: 2px solid #635c5c;
}

.z-flex-footer {
    display: flex;
    justify-content: flex-start;
    padding-top: 35%;
    margin-bottom: 10%;
}

.z-footer-terms2 {
    font-family: ProductSans-Regular;
    font-size: 22px;
    letter-spacing: 6px;
}

.z-footer-subscribe p {
    color: #3a3a4c;
    font-size: 26px;
    font-family: ProductSans-Light;
}

.z-footer-social i {
    font-size: 24px;
    margin-right: 13%;
}

.z-footer-input {
    height: 48px;
    font-size: 21px;
    border-radius: 100px;
    font-family: ProductSans-Regular;
    padding: 6px 29% 6px 11px;
}

.z-footer-button {
    position: absolute;
    height: 48px !important;
    padding: 0 50px !important;
    font-size: 18px !important;
    right: 0;
    background: #273659;
    border-color: #273659;
    font-family: ProductSans-Medium;
}

.z-footer-input:hover,
.ant-select-selection:hover {
    border: 1px solid #cecece !important;
    border-radius: 40px;
}

.z-footer-input:focus,
.ant-select-selection:focus {
    border-color: #ddd;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(210, 210, 210, 0.2);
}

.z-footer-button:hover {
    color: #fff;
    background-color: #273659d1;
    border-color: #273659;
}

.z-footer-button:focus {
    color: #273659;
    background-color: #fff;
    border-color: #273659;
}

.z-custom .ant-modal-content {
    width: 100% !important;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    /* min-height: 65vh;*/
    min-height: 60vh;
    height: 530px;
    border-radius: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 5%;
}

.z-custom {
    width: 45% !important;
    height: 100vh;
    padding: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
}

.z-modal-welcome .ant-modal-content {
    background-image: url("../../assets/images/background scattering.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 2%;
}

.z-welcome h2 {
    font-size: 26px;
    color: #6d79a0 !important;
}

.z-welcome-icon img {
    height: 100%;
}

.z-welcome-icon {
    height: 150px;
    margin-top: 3%;
}

.z-success-icon {
    height: 300px;
    margin-top: 3%;
}

.z-mt-13 {
    margin-top: 12%;
}

.z-mt-13_ {
    margin-top: 13%;
}

.z-mb-6 {
    margin-bottom: 6%;
}

.z-mb-4 {
    margin-bottom: 4% !important;
}

.z-button-plain {
    background: #fff !important;
    color: #373d56 !important;
    border: 2px solid #777777 !important;
}

.z-button-gradient {
    background-image: linear-gradient(
        to left,
        #817cb7,
        #977cbb,
        #af7cbd,
        #c57bba,
        #db7ab4
    ) !important;
    /* border-right: 1pt solid #525252!important;*/
}

.z-button-gradient:hover,
.z-button-gradient:focus,
.z-button-gradient:active,
.z-gradient:hover,
.z-gradient:focus,
.z-gradient:active {
    color: #fff;
    background-color: #fff;
    border-color: #db7ab4;
}

.z-modal-signup .ant-modal-content {
    background-image: url("../../assets/images/side in.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    padding: 2%;
}

.z-login-inactive {
    font-size: 14px !important;
    color: rgba(82, 93, 124, 0.3) !important;
    line-height: 20px !important;
    margin-bottom: 0 !important;
    cursor: pointer;
    font-family: ProductSans-Medium !important;
    letter-spacing: -1px;
}

.z-login-active {
    font-size: 25px !important;
    color: #525d7c !important;
    line-height: 40px !important;
    margin-bottom: 0 !important;
    cursor: pointer;
    font-family: ProductSans-Medium !important;
    letter-spacing: -1px;
}

.z-mr-25 {
    margin-right: 20%;
}

.z-flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.z-flex-baseline {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
}

.z-flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.z-mr-20 {
    margin-right: 30px;
}

.z-signup-hr hr {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 25%;
    border: 0;
    border-bottom: 3px solid #c5cce3;
    width: 10%;
    border-radius: 39px;
}

.z-signup-hr {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 70%;
    margin: 1% 0 8% 15%;
}

.z-mt-3 {
    margin-top: 1%;
}

.z-mt-3_ {
    margin-top: 3%;
}

.z-mt-10 {
    margin-top: 10%;
}

.z-signup-parag {
    /* width: 63%; */
    padding: 0 18%;
    font-size: 15px;
    color: #525d7c80;
    text-align: center;
    line-height: 20px;
    margin: auto;
    /* font-family: ProductSans-Medium;*/
    font-family: ProductSans-Medium;
}

.z-signup-header {
    text-align: center;
    font-size: 22px !important;
    color: #525d7c !important;
    line-height: 40px !important;
    margin-bottom: 7%;
    /*font-family: ProductSans-Medium*/
    font-family: ProductSans-Medium;
    letter-spacing: -1px;
}

.z-signup-company {
    height: 40px !important;
    font-size: 14px !important;
    border-radius: 100px;
    font-family: Raleway-Light;
    padding: 6px 6px 6px 30px !important;
    width: 85%;
    margin-bottom: 6%;
}

.z-signup-small {
    height: 42px !important;
    width: 115px !important;
    /* margin-right: 3%; */
    font-size: 14px !important;
    background-image: linear-gradient(
        to left,
        #b7a9ca,
        #bea3c9,
        #c69cc6,
        #cf94c0,
        #d98cb7
    ) !important;
}

.z-mr-2 {
    margin-right: 2%;
}
.z-mr-3 {
    margin-right: 5%;
}

.z-mt-26 {
    /*margin-top: 24%;*/
    margin-top: 26%;
}

.z-mt-20 {
    /*margin-top: 24%;*/
    margin-top: 20%;
}

.z-mt-18 {
    margin-top: 20%;
}

.z-mb-20 {
    margin-bottom: 17%;
}

.z-step {
    height: 12vh;
}

.z-step .ant-steps-item-tail:after {
    background-color: #eee;
}

.z-step .ant-steps-item-tail {
    position: absolute !important;
    top: 0 !important;
    left: 12px !important;
    padding: 56px 0 30px !important;
}

.z-step .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
    background-image: linear-gradient(
        to left,
        #b7a9ca,
        #bea3c9,
        #c69cc6,
        #cf94c0,
        #d98cb7
    ) !important;
    border-color: #bea3c9;
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: rgba(98, 99, 99, 0.3);
    border-color: rgba(98, 99, 99, 0);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-icon {
    color: #fff;
    font-family: ProductSans-Medium;
    font-size: 10px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
}

.z-btn-img {
    height: 12px;
    margin-left: 20%;
}

.z-select {
    font-family: Raleway-Medium;
    width: 85%;
}

.ant-select-lg .ant-select-selection--single {
    height: 40px !important;
    font-size: 13px !important;
    border-radius: 100px;
    font-family: ProductSans-Regular;
    /* padding: 6px 6px 6px 11px !important; */
    padding-left: 5%;
    width: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid #47467747;
}

.ant-select-selection-selected-value img,
.ant-select-dropdown-menu-item img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 5%;
}

.z-select-input {
    text-transform: uppercase;
    font-size: 13px !important;
}

.ant-select-selection__rendered,
.ant-select-selection-selected-value {
    width: 100%;
}

.no-mb {
    margin-bottom: 0 !important;
}

.z-signup-password {
    padding: 0 !important;
    width: 85%;
    margin: auto;
}

.z-signup-password input {
    height: 40px !important;
    font-size: 14px !important;
    border-radius: 100px;
    font-family: ProductSans-Regular;
    padding: 6px 6px 6px 30px !important;
    width: 100%;
}

.ant-steps-item-finish .ant-steps-item-icon {
    /*background-color: rgba(98, 99, 99, 0.3);*/
    background-color: #d4c2e7;
    border-color: rgba(98, 99, 99, 0);
    background-image: none !important;
}

.z-mr--25 {
    margin-right: 25px;
}

.z-hr-login {
    margin-right: 67% !important;
}

.z-login-mt {
    margin-top: 9%;
}

.z-login-mb {
    margin-bottom: 6% !important;
}

.z-login-input,
.z-login-input2 input {
    height: 45px !important;
    border: 1px solid #47467747;
    font-family: Raleway-Light;
}

.z-login-btn {
    font-size: 14px !important;
    width: 260px !important;
}

.z-mt-15 {
    margin-top: 22%;
}

.z-mt-15_ {
    margin-top: 15%;
}

.z-gradient {
    background-image: linear-gradient(
        to left,
        #b7a9ca,
        #bea3c9,
        #c69cc6,
        #cf94c0,
        #d98cb7
    ) !important;
}

.z-p-small {
    color: #525d7c;
    font-size: 14px;
    margin-bottom: 0;
    font-family: Raleway-Medium;
}

.z-mt-6 {
    margin-top: 6%;
}

.z-mt-8 {
    margin-top: 8%;
}

.z-btn-img2 {
    height: 12px;
    /* margin-right: 2%; */
    margin-right: 12%;
    margin-left: -12%;
}

.z-complete-p {
    color: rgba(95, 107, 137, 0.7);
    font-size: 20px;
    margin: 9% 17% 0;
    font-family: ProductSans-Regular;
    text-align: center;
}

.z-complete-btn {
    height: 42px !important;
    width: 115px !important;
    /* margin-right: 3%; */
    font-size: 14px !important;
}

.z-mb-3 {
    margin-bottom: 4%;
}

.z-mb-31 {
    margin-bottom: 3% !important;
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo img {
    height: 100%;
}

.logo {
    height: 27px;
    margin: 12% 15% 26%;
}

.z-dashboard .ant-layout-content {
    min-height: 200vh !important;
    background: #fcfdff !important;
    margin: 0 !important;
    padding: 3% 5% 4% !important;
}

.z-dashboard .ant-layout {
    background: #fcfdff !important;
}

.z-dashboard-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 0 8% 12%;
    background: #fff;
    border: 1px solid #dadada;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: #000728;
}

.z-dashboard .ant-layout-sider {
    background: #000728;
    flex: 0 0 210px !important;
    max-width: 210px !important;
    min-width: 210px !important;
    width: 210px !important;
    box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.4);
}

.z-dashboard .ant-layout-header {
    height: 75px;
    background: #000728 !important;
    padding: 1% 7%;
    margin-top: 1%;
}

.z-dashboard .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}

.z-dashboard .ant-menu-item {
    padding-left: 8% !important;
    height: 48px;
    font-family: Raleway-Medium;
    font-size: 13px;
    margin: 10px 0;
    /* color: #EAEAEA;*/
}

.z-nav-hr hr {
    border: 0;
    width: 85%;
    margin: 8% auto 15%;
    border-bottom: 1px solid #494949;
}

.z-nav-icon-active {
    background: #1890ff;
}

.z-nav-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 3% 4%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8%;
}

.z-nav-icon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.z-dashboard .ant-menu.ant-menu-dark .ant-menu-item-selected .z-nav-icon,
.z-dashboard
    .ant-menu-submenu-popup.ant-menu-dark
    .ant-menu-item-selected
    .z-nav-icon {
    background: #1890ff;
}

.z-dashboard .ant-menu.ant-menu-dark .ant-menu-item-selected,
.z-dashboard .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(234, 234, 234, 0.1);
    color: #fff;
}

.z-nav-i i {
    color: white;
    font-size: 11px;
}

.z-nav-i {
    margin-left: 5%;
}

.z-nav-header h2 {
    color: #fcfcfc;
    font-family: Raleway-Regular;
    font-size: 26px;
    line-height: 48px;
    margin-bottom: 0;
    margin-top: 0;
    letter-spacing: -2px;
}

.z-nav-header-p {
    color: #fcfcfc;
    margin-bottom: 0;
    font-size: 13px;
    font-family: CircularStd-Book;
    text-transform: lowercase;
    height: 30px;
    border: 1px solid #fcfcfc;
    padding: 0 4%;
    width: 50%;
    line-height: 31px;
    border-radius: 32px;
    margin-left: 7%;
    display: flex;
    justify-content: center;
}

.z-nav-search-image-more.z-more-top::before {
    left: 45%;
    top: -22px;
    border-bottom: 12px solid #fff;
}

.z-nav-search-image-more::before {
    content: "";
    width: 0px;
    height: 0px;
    border: 0.8em solid transparent;
    position: absolute;
}

.z-nav-search-image-more {
    width: 385px;
    height: 270px;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 40px 0 0px 0;
    margin: auto;
    background: rgb(81, 170, 251);
    background: linear-gradient(
        0deg,
        rgba(81, 170, 251, 1) 99%,
        rgba(34, 249, 244, 1) 100%
    );
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    left: -225%;
    top: 55px;
    padding: 3px 10px 10px 10px;
    overflow: hidden;
}

.z-nav-search-image-more:hover {
    overflow-y: auto;
}

.z-nav-search-image-more p {
    margin-bottom: 0;
    font-size: 13px;
    color: rgba(155, 155, 155, 1);
    margin-left: 8%;
    font-family: ProductSans-Regular;
}

.z-nav-search-image:hover .z-nav-search-image-more {
    display: block;
}

.z-nav-search-image {
    height: 60px;
    width: 84px;
    margin-right: 35%;
    position: relative;
}

.z-nav-search input {
    height: 30px;
    border-radius: 20px;
}

.z-flex-row2 {
    display: -webkit-flex;
    display: flex;
    /* -webkit-align-items: center; */
    align-items: baseline;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.z-dashboard-label {
    color: rgba(40, 40, 76, 1);
    font-size: 22px;
    font-family: MollenPersonalUse-Narrow, sans-serif;
    margin-bottom: 3%;
    letter-spacing: -1px;
}

.z-dashboard-panel {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 70px 90px #00000005;
    opacity: 1;
    /*top: 217px;*/
    /*left: 355px;*/
    width: 80vw;
    height: auto;
    padding: 1% 2%;
}

.z-label-icon {
    color: rgba(102, 102, 102, 1);
    font-size: 18px;
    margin-right: 2%;
}

.z-label-text {
    font-size: 16px;
    font-family: MollenPersonalUse-Bold, serif;
    text-align: left;
    letter-spacing: 0;
    color: #000728;
    opacity: 0.6;
}

.z-font-16 {
    font-size: 16px;
}

.z-font-14 {
    font-size: 14px !important;
}

.z-font-15 {
    font-size: 15px !important;
}

.z-dashboard-nav {
    font-weight: 800;
    font-size: 16px;
    color: rgba(5, 5, 5, 1);
}

.z-pl-10 {
    padding: 0 10%;
}

.z-pl-8 {
    padding: 0 8%;
}

.z-mb-10 {
    margin-bottom: 10%;
}

.z-mb-8 {
    margin-bottom: 8% !important;
}

.z-mb-15 {
    margin-bottom: 15% !important;
}

.z-mb-9 {
    margin-bottom: 9% !important;
}

.z-mb-7 {
    margin-bottom: 7%;
}

.z-dashboard-tab p {
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    color: rgba(79, 79, 79, 0.3);
    font-family: ProductSans-Regular;
    line-height: 18px;
}

.z-dashboard-tab {
    width: 155px;
    height: 120px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    margin: auto;
    position: relative;
    background: #fdfdfd;
    padding: 2%;
    cursor: pointer;
}

.z-width-75 {
    width: 75%;
}

.z-tab-active {
    width: 210px;
    height: 160px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    border-radius: 35px;
    background: #fff;
    z-index: 10;
    left: -20%;
    right: 0;
    top: -20%;

    margin: auto;
}

.z-tab-button:hover {
    transform: none !important;
    cursor: none;
}

.z-tab-button {
    height: 30px !important;
    width: 84px !important;
    /* margin-right: 3%; */
    margin-bottom: 0 !important;
    margin-top: 15%;
    font-size: 11px !important;
    border: 0;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1) !important;
    transition: none !important;
}

.z-gradient2 {
    background: linear-gradient(
        90deg,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 1%,
        rgba(229, 199, 255, 1) 100%
    ) !important;
}

.z-gradient3 {
    background: linear-gradient(
        104deg,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 18%,
        rgba(229, 199, 255, 1) 100%
    ) !important;
}

.z-gradient4 {
    /*background: rgb(74,255,222);
    background: linear-gradient(104deg, rgba(74,255,222,1) 0%, rgba(99,174,255,1) 29%, rgba(229,199,255,1) 100%)!important;*/
    background: rgb(74, 255, 222);
    background: linear-gradient(
        104deg,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 41%,
        rgba(229, 199, 255, 1) 100%
    ) !important;
}

.z-gradient5 {
    background: rgb(74, 255, 222);
    background: linear-gradient(
        104deg,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 100%,
        rgba(229, 199, 255, 1) 100%
    ) !important;
}

.z-tab-active p {
    color: rgba(40, 40, 76, 1) !important;
    font-size: 18px !important;
    text-align: center !important;
    font-family: ProductSans-Medium !important;
    line-height: 21px !important;
}

.z-tab-button-active:hover {
    transform: translateY(-7px) !important;
}

.z-tab-button-active {
    height: 35px !important;
    width: 120px !important;
    /* margin-right: 3%; */
    margin-bottom: 0 !important;
    margin-top: 20%;
    font-size: 14px !important;
    border: 0;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.1) !important;
    cursor: pointer !important;
}

.z-cursor-poiner {
    cursor: pointer;
}

.z-disabled {
    color: #d2d1d1 !important;
    cursor: not-allowed !important;
}

.z-dashboard-side {
    padding: 4% 2%;
}

.z-dashboard-progress-div .ant-progress-inner {
    width: 110px !important;
    height: 110px !important;
    font-size: 24px;
}

.z-dashboard-progress-div {
    background: #28428a 0 0 no-repeat padding-box;
    height: 135px;
    width: 244px;
    box-shadow: 0 70px 90px #0007281a;
    border-radius: 27px;
    opacity: 1;
}

.z-dash-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #e72600;
}

.z-dash-div {
    position: absolute;
    top: -5px;
    right: -6px;
    font-size: 14px;
    border: 2px solid #e72600;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-dash-div i {
    position: absolute;
    font-size: 14px;
    color: #e72600;
}

.z-progress .ant-progress-text {
    font-family: ProductSans-Regular;
    font-size: 20px;
    color: white;
}

.z-height-45 {
    min-height: 45vh;
}

.z-label-small {
    color: #969696;
    font-size: 9px;
    margin-bottom: 3%;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: ProductSans-Medium;
}

.z-label-big {
    color: #3c3c77;
    font-size: 18px;
    margin-bottom: 0;
    font-family: ProductSans-Regular;
}

.z-mb-18 {
    margin-bottom: 18%;
}

.z-dash-p {
    padding: 2% 10% !important;
}

.z-ml-10 {
    margin-left: 10%;
}

.z-ml-5 {
    margin-left: 5% !important;
}

.z-ml-4 {
    margin-left: 4% !important;
}

.z-ml-6 {
    margin-left: 6% !important;
}

.z-ml-8 {
    margin-left: 8%;
}

.z-ml-15 {
    margin-left: 15% !important;
}

.z-mr-5 {
    margin-right: 5%;
}

.z-naked-btn {
    color: #28284c;
    border: 1px solid #6aaaff;
    width: 12%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    font-size: 14px;
    margin: 0;
}

.z-timeline p {
    margin-bottom: 0;
    font-size: 13px;
    color: rgba(93, 93, 93, 0.6);
    margin-left: 2%;
    font-family: Raleway-Medium;
}

.z-timeline i {
    color: rgba(93, 93, 93, 0.6);
    font-size: 13px;
}

.z-mt-4 {
    margin-top: 4%;
}

.z-mb-1 {
    margin-bottom: 1%;
}

.z-mb-2 {
    margin-bottom: 2% !important;
}

.z-mb-5 {
    margin-bottom: 5% !important;
}

.z-nav {
    display: -webkit-flex;
    display: flex !important;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    height: inherit;
    color: inherit;
}

.z-profile-card {
    min-height: 100vh;
    width: 90%;
    margin: auto;
    background: #fcfcfc;
    padding: 2% 5% !important;
}

.z-profile-avatar {
    width: 80px;
    height: 80px;
    border: 3px solid #fff;
    margin: 0;
    position: relative;
}

.z-profile-small {
    color: #000728;
    text-align: center;
    font-family: MollenPersonalUse-Regular, sans-serif;
    border-bottom: 2px solid rgba(167, 169, 170, 1);
    width: fit-content;
    font-size: 12px;
    margin: auto;
    cursor: pointer;
}

.z-profile-display {
    width: 100%;
    background: rgba(249, 249, 249, 1);
    border: 0;
    height: 35px !important;
    padding: 2% 8% !important;
    font-size: 13px !important;
}

.z-width-75_ {
    width: 75% !important;
    padding-left: 15% !important;
    padding-right: 2% !important;
}

.z-float {
    float: right;
}

.z-profile-button {
    height: 40px !important;
    width: 160px !important;
    font-size: 13px !important;
}

.z-notification-div {
    padding: 5% 0 !important;
}

.z-notification-tab {
    padding: 0 10% !important;
}

.z-width-60 {
    width: 60%;
}

.z-width-40 {
    width: 40%;
}

.z-notification-tab p {
    margin-bottom: 0;
    font-size: 14px;
    font-family: MollenPersonalUse-Narrow, sans-serif;
}

.z-notification-active {
    color: #000728;
}

.z-notification-inactive {
    color: rgba(71, 92, 122, 0.3) !important;
}

.z-notification-inactive-right {
    margin: auto auto auto 55% !important;
}

.z-notification-inactive-left {
    border: 0 !important;
    border-bottom: 2px solid rgba(154, 221, 239, 0.5) !important;
    margin: auto auto auto 10% !important;
    width: 20% !important;
}

.z-notification-tab-div hr {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 1%;
}

.z-notification-tab-div {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 1%;
}

.z-notification-header {
    color: rgba(27, 33, 68, 0.7);
    font-size: 16px;
    font-family: CircularStd-Mediun;
}

.z-pl-25 {
    padding-left: 25%;
}

.z-todo-div p {
    font-size: 14px !important;
    margin-left: 10% !important;
    margin-right: 5% !important;
    font-family: ProductSans-Regular !important;
    color: rgba(145, 149, 155, 1) !important;
}

.z-todo-div {
    padding: 0 10% 0 8%;
}

.z-todo .ant-checkbox-inner {
    border-radius: 25px;
    background: #f4f4f4;
}

div.z-todo {
    width: 75vw;
}

.zz-flex {
    display: flex;
}

.z-height-40 {
    min-height: 40vh;
}

.z-width-30 {
    width: 30% !important;
}

.z-width-35 {
    width: 35% !important;
}

.z-log-modal .ant-modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.z-log-modal .ant-modal-close-x {
    font-size: 12px;
}

.z-log-modal {
    width: 16% !important;
}

/*.z-log-modal .ant-modal-content {
    min-height: 25vh;
}*/
.z-modal-text {
    color: #7f7f7f;
    font-size: 16px;
    text-align: center;
}

.z-modal-btn {
    height: 35px !important;
    width: 75px !important;
    font-size: 13px !important;
}

.z-btn-nobg {
    background: #fff;
    color: #373d56;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.z-apply-shadow {
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.05) !important;
}

.z-apply-label {
    margin-bottom: 0;
    font-size: 15px;
    text-align: left;
    font-family: MollenPersonalUse-Regular, sans-serif;
    letter-spacing: 0;
    color: #000728;
    opacity: 0.8;
}

.z-apply-label-small {
    font-size: 17px;
    font-family: MollenPersonalUse-Regular, sans-serif;

    text-align: left;
    letter-spacing: 0;
    color: #000728;
    opacity: 0.8;
}

.z-apply-card {
    min-height: 10vh;
    height: 62px;
    /*border-bottom-right-radius: 15px;*/
    margin-top: 3%;
    padding: 3% 4%;
    margin-bottom: 15%;

    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 30px 50px #82828233;
    opacity: 1;
}

.z-apply-tag p {
    margin-bottom: 0;
    font-size: 11px;

    text-align: left;
    font-family: MollenPersonalUse-Regular, sans-serif;
    letter-spacing: -0.54px;
    color: #000728;
    opacity: 0.46;
}

.z-apply-input:hover,
.z-apply-input:focus {
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 40px !important;
    box-shadow: none !important;
}

.z-apply-input {
    /*height: 40px !important;*/
    border-radius: 0;
    font-size: 17px !important;
    border: none;
    background: none;

    text-align: left;
    font-family: MollenPersonalUse-Regular, sans-serif;
    letter-spacing: -0.54px;
    color: #000728;
    opacity: 0.46;

    margin-bottom: 0;
    padding: 0 12% 0 8% !important;
    text-transform: capitalize;
}

.z-width-45 {
    width: 45%;
}

.z-height-90 {
    min-height: 90vh;
}

.z-slider .ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    margin-left: -7px;
    background-color: #71bfff;
    border: solid 2px #f9efef;
}

.z-slider .ant-slider-track {
    position: absolute;
    height: 2px;
    background-color: #71bffff2;
}

.z-slider .ant-slider-handle:hover .z-slider:hover {
    border: solid 2px #71bfff3b;
}

.z-slider .ant-slider-track:hover .z-slider:hover {
    background-color: #71bfff3b;
}

.ant-slider:hover .ant-slider-track {
    background-color: #71bfff3b;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #71bfff3b;
}

.ant-slider-step {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #71bfff3b;
}

.z-apply-input-small {
    height: 40px !important;
    font-size: 13px !important;
    padding: 0 5% !important;
    text-transform: capitalize;

    box-shadow: 0 20px 30px #00000008;
    border: 1px solid #7070702b;
    border-radius: 10px;
    opacity: 1;
}

.z-apply-input-small:hover,
.z-apply-input-small:focus {
    box-shadow: 0 20px 30px #00000008 !important;
    border: 1px solid #7070702b !important;
    border-radius: 10px !important;
    opacity: 1;
}

.z-lowercase {
    text-transform: lowercase !important;
}

.z-width-65 {
    width: 65% !important;
}

.z-width-44 {
    width: 44% !important;
}

.z-width-43 {
    width: 43% !important;
}

.z-mr-6 {
    margin-right: 6%;
}

.z-ml-6 {
    margin-left: 6%;
}

.z-mr-7 {
    margin-right: 7%;
}

.z-ml-7 {
    margin-left: 7%;
}

.z-btn-small {
    height: 42px !important;
    width: 115px !important;
}

.z-apply-side-div p,
.z-apply-side-div i {
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
    font-family: MollenPersonalUse-Regular, sans-serif;
    letter-spacing: 0;
    color: #000728;
    opacity: 0.8;
}

.z-apply-side {
    min-height: 25vh;
    background: #fcfcfc;
    width: 45%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}

.z-apply-pad {
    padding: 5% 7%;
}

.z-apply-note2 {
    background: #28c3ff !important;
}

.z-apply-note1 {
    height: 100px;
    background-color: #28428a;
    background-repeat: no-repeat;
    background-size: 56% 63%;
    background-position: left bottom;
    background-image: url("../../assets/images/dashboard/existingLoan.svg");
    opacity: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    padding: 4%;
}

.z-aside p {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 6px;
    margin-bottom: 0;
    font-family: Raleway-Medium;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    transform-origin: 25% 20%;
    transform: rotate(90deg);
    color: rgba(255, 255, 255, 0.7);
}

.z-aside {
    display: block;
    position: relative;
}

.z-aside-tab {
    width: 100%;
    margin-bottom: 0;
}

.z-aside-tab p {
    margin-bottom: 0;
    /* margin-left: 50%; */
    width: 100%;
    color: rgba(255, 255, 255, 1);
    font-family: ProductSans-Medium;
    margin-left: 25%;
    text-transform: capitalize;
}

.z-as-bottom {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 10px;
    margin-top: 6% !important;
}

.z-as-top {
    text-align: left;
    font-family: MollenPersonalUse-Regular, sans-serif;
    letter-spacing: 0;
    color: #ffffff !important;
    opacity: 0.8;
    font-size: 8px;
}

.z-flex-even {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.z-transform2 {
    transform-origin: 5% 120% !important;
}

.z-color-white {
    color: rgba(255, 255, 255, 1);
}

.z-pro-inherit {
    height: inherit !important;
}

.z-mr-10 {
    margin-right: 10% !important;
}

.z-mr-17 {
    margin-right: 17%;
}

.z-flex-space2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.z-margin-auto {
    margin: auto;
}

.z-mb-14 {
    margin-bottom: 14%;
}

.z-p-10 {
    padding: 0 10%;
}

.z-new-active {
    background: #efeded !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
}

.z-new-tab p {
    color: #c6c6c6;
    margin-bottom: 0;
    font-family: ProductSans-Regular;
    font-size: 13px;
}

.z-new-tab {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 30px 50px #0000000d;
    border-radius: 20px;
    opacity: 1;

    width: 230px;
    height: 155px;
    margin: auto;
    position: relative;
    padding: 4% 4% 5% 6%;
    cursor: pointer;
}

.z-new-dIcon i {
    color: #fff;
}

.z-new-dIcon {
    height: 25px;
    width: 25px;
    border: 4px solid #f1f2f2;
    border-radius: 50%;
    background: rgb(99, 174, 255);
    background: linear-gradient(
        90deg,
        rgba(99, 174, 255, 1) 79%,
        rgba(74, 255, 222, 1) 99%
    );
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-flex-start-base {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
}

.z-new-amount {
    font-size: 25px !important;
    margin-left: 7px;
    font-family: Maven-Pro, sans-serif !important;
    color: #102c56 !important;
    letter-spacing: -2px;
}

.z-dash-apply {
    min-height: 85vh;
    /* height: 840px; */
    height: 100%;
    min-width: 80vw;
}

.z-mr-15 {
    margin-right: 15%;
}

.z-div-arrow i {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.z-upload-flex {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.z-btn-medium {
    height: 42px !important;
    width: 161px !important;

    background: #28428a 0 0 no-repeat padding-box !important;
    border-radius: 36px;
    letter-spacing: 0;
    color: #ffffff !important;
    opacity: 0.9;

    /*background: rgb(74,255,222);*/
    /*background: linear-gradient(90deg, rgba(74,255,222,1) 0%, rgba(99,174,255,1) 100%, rgba(229,199,255,1) 100%);*/
}

.z-btn-medium:disabled {
    opacity: 0.8;
}

.z-pro-mar {
    padding: 0 7%;
}

.z-profile-dIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
        to right top,
        #60a6fd,
        #00c0ff,
        #00d7ff,
        #00ecff,
        #32fff0
    ) !important;
    height: 20px !important;
    width: 20px !important;
    border: 3px solid #f1f2f2 !important;
    cursor: pointer;
}

.z-profile-label-input:hover,
.z-profile-label-input:focus {
    border-bottom: 1px solid #e0e0e0 !important;
    background: #f5f5f5;
}

.z-profile-label-input {
    font-size: 16px !important;
    color: #626363 !important;
    font-family: ProductSans-Regular !important;
    padding: 0 2% 0 1% !important;
}

.z-profile-width {
    width: 46%;
    margin-right: 4%;
}

.z-profile-label {
    color: #000728;
    margin-bottom: 0;
    font-size: 15px;
    font-family: MollenPersonalUse-Regular, sans-serif;
}

.z-p-p {
    padding: 0 0 0 3%;
}

.z-button-plain2 {
    background: #fff !important;
    color: #999 !important;
    border: 2px solid #27aae1 !important;
    box-shadow: none;
}

.z-profile-gradient {
    background: rgb(74, 255, 222);
    background: linear-gradient(
        90deg,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 100%,
        rgba(229, 199, 255, 1) 100%
    );
    box-shadow: none;
}

.z-notification-dIcon i {
    color: rgba(159, 218, 255, 1);
    font-size: 14px;
}

.z-notification-dIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid rgba(230, 231, 232, 1);
}

.z-not-left i {
    color: #fff;
    font-size: 9px;
}

.z-not-left {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgba(159, 218, 255, 1);
    box-shadow: 0 3px 4px rgba(153, 221, 255, 0.45);
    cursor: pointer;
}

.z-not-right {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-family: ProductSans-Regular;
    color: rgba(153, 153, 153, 1);
    font-size: 10px;
    border: 1px solid rgba(159, 218, 255, 1);
    cursor: pointer;
}

.z-nav-holder-p {
    font-family: ProductSans-Regular;
    color: rgba(153, 153, 153, 1);
    font-size: 10px;
    margin-bottom: 0;
}

.z-nav-holder {
    width: 22%;
    margin: auto;
    margin-top: 10%;
}

.z-guarantor-panel {
    padding: 0;
    /*min-height: 135vh;
    height: 85vh;*/
    max-height: 135vh;
    height: 100%;
    position: relative;
    min-height: 500px !important;
}

.z-guarantor-padding {
    padding: 3% 15%;
}

.z-guarantor-padding2 {
    padding: 0 15%;
}

.z-guarantor-active {
    border-bottom: 2px solid #d7effd;
    /*width: 60%;*/
}

.z-guarantor-tab {
    margin-bottom: 0;
    color: rgba(117, 117, 117, 1);
    font-size: 13px;
    font-family: CircularStd-Book;
}

.z-table-row {
    background: rgba(160, 160, 160, 0.04);
    min-height: 55px;
    margin-bottom: 1%;
}

.z-table-row-p {
    text-align: center;
    margin-bottom: 0;
    margin-right: 3%;
    color: #000728;
    font-size: 13px;
    font-family: MollenPersonalUse-Bold, sans-serif;
}

.z-guarantor-padding3 {
    padding: 0 12% 0 6%;
}

.z-drIcon i {
    font-size: 9px;
}

.z-drIcon {
    margin: auto;
    height: 20px;
    width: 20px;
    background: rgba(123, 255, 160, 1);
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(148, 255, 194, 0.25);
}

.z-gr-div {
    /* position: relative; */
    margin: 0;
    margin-bottom: 40px;
    width: 25%;
    /* left: 37%; */
    margin-top: 65px;
}

.z-admin-gr-div {
    width: 60%;
    margin: auto;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
}

.z-custom2 .ant-modal-content {
    min-height: 69vh;
    border-radius: 69px;
}

.z-custom2 .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #f9f9f9;
    border-bottom: 0;
    border-radius: 69px 69px 0 0;
    min-height: 13vh;
    height: 90px;
}

.z-custom2 .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 69px 69px;
    background: #69b9f9;
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 13vh;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-custom2 .ant-modal-title {
    color: #f9f9f9;
}

.z-custom2 .ant-modal-close-x {
    font-size: 12px;
}

.z-custom2 {
    width: 35% !important;
    border-radius: 69px;
}

.z-skeleton-btn i {
    font-size: 10px;
}

.z-skeleton-btn {
    background: none !important;
    color: #ffffff !important;
    border: 2px solid #ffffff !important;
    box-shadow: none;
    width: 130px !important;
    height: 38px !important;
}

.z-skeleton-bg {
    background: #fff !important;
    color: #69b9f9 !important;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    margin-left: 5% !important;
}

.z-modal-nav {
    position: absolute;
    top: 0;
    width: 31%;
    margin-top: 9%;
    left: 34%;
}

.z-modal-b {
    background: rgba(153, 153, 153, 0.1);
}

.z-img-100 {
    width: 110px;
    height: 110px;
}

.z-modal-header {
    color: rgba(86, 86, 86, 1);
    font-size: 24px;
    font-family: ProductSans-Medium;
    margin-bottom: 8%;
    max-height: 67px;
    overflow: hidden;
}

.z-modal-left p {
    color: rgba(142, 142, 142, 1);
    font-size: 11px;
    font-family: CircularStd-Book;
    margin-bottom: 16px;
}

.z-modal-right p {
    color: rgba(60, 61, 61, 1);
    font-size: 13px;
    font-family: CircularStd-Book;
    padding-right: 10px;
    padding-left: 15px;
}

.z-width-56 {
    width: 56%;
}

.z-ml-2 {
    margin-left: 2%;
}

.z-width-90 {
    width: 90%;
}

.z-file-div p {
    margin-bottom: 0;
    height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.z-file-div {
    background: rgba(237, 237, 237, 1);
    font-size: 12px;
    height: 28px;
    border-radius: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    width: 65%;
    text-align: center;
}

.z-loan-padding {
    padding: 0 4% 0 6%;
}

.z-color-gray {
    color: #dddddd;
}

.z-table-label {
    color: #000728;
    font-family: MollenPersonalUse-Regular, sans-serif;
    font-size: 10px;
    margin-bottom: 0;
}

.z-color-green {
    color: #95ffb9 !important;
}

.z-color-orange {
    color: gold !important;
}

.z-color-red {
    color: #ff6c71;
}

.z-flex-column-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.z-nbm {
    border-bottom: 0;
}

.z-custom3 .ant-modal-body {
    padding: 0;
}

.z-custom3 .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    border-top: 0;
    border-radius: 0 0 69px 69px;
    background: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 13vh;
    height: 90px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.z-loan-btn {
    height: 45px !important;
    width: 225px !important;
    font-size: 13px !important;
    background: rgb(99, 174, 255);
    background: linear-gradient(
        83deg,
        rgba(99, 174, 255, 1) 99%,
        rgba(229, 199, 255, 1) 100%,
        rgba(74, 255, 222, 1) 100%
    );
}

.z-loan-btn:disabled {
    color: white;
}

.z-loan-drIcon i {
    font-size: 10px;
}

.z-loan-drIcon {
    margin: 0;
    height: 16px;
    width: 16px;
    background: #00c9ff;
    border: 0;
    box-shadow: 0 2px 4px rgba(148, 255, 194, 0.25);
}

.z-loan-padding2 {
    padding: 0 17%;
}

.z-loan-graph {
    min-height: 22vh;
}

.z-modal-label-div {
    position: absolute;
    width: 100%;
    top: 8%;
    padding: 0 12%;
}

.z-naked-modal {
    color: #39ff88;
    border: 2px solid #bcbec0;
    width: 17% !important;
    height: 31px;
    line-height: 29px;
    text-align: center;
    text-transform: capitalize;
    font-size: 10px;
    margin: 0 !important;
    padding: 0 3%;
    background: #fff;
}

.z-loan-header-small {
    font-size: 9px !important;
    letter-spacing: 3px !important;
    text-transform: uppercase;
}

.z-loan-header {
    color: #7a7a7a;
    font-size: 20px;
    font-family: CircularStd-Book;
    margin-bottom: 0;
    letter-spacing: -2px;
}

.z-custom3 .ant-modal-close {
    display: none;
}

.z-loan-p3 {
    color: #c4c4c4 !important;
    font-size: 12px !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    font-family: ProductSans-Medium !important;
    width: 100%;
}

.z-font-xs {
    font-size: 8px !important;
}

.z-loan-p2 {
    color: #d1d1d1 !important;
    font-size: 11px;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    font-family: ProductSans-Medium;
    width: 100%;
}

.z-loan-p {
    margin-bottom: 0;
    /* margin-left: 50%; */
    width: 100%;
    color: #727272 !important;
    font-family: Roboto-Regular !important;
    margin-left: 0 !important;
    letter-spacing: -1px !important;
    font-size: 28px !important;
}

.z-gr-p {
    padding: 0 12%;
}

.z-noborder {
    border: 0 !important;
}

.z-icon-skeleton i {
    transform: rotate(45deg);
}

.z-icon-skeleton {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    font-family: ProductSans-Regular;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    border: 2px solid #92ceff;
    cursor: pointer;
    color: #92ceff;
}

.z-admin-tab p {
    color: rgba(122, 122, 122, 1);
    margin-bottom: 0;
    font-family: ProductSans-Regular;
}

.z-admin-tab {
    width: 43%;
    height: 190px;
    box-shadow: 0 3px 6px rgba(81, 206, 143, 0.2);
    border-radius: 13px;
    margin: auto;
    position: relative;
    background: #fff;
    padding: 4% 4% 5% 6%;
    cursor: pointer;
}

.z-letter-spacing {
    letter-spacing: -2px;
}

.z-bottom-label p {
    color: rgba(183, 183, 183, 1);
}

.z-mb-25 {
    margin-bottom: 25%;
}

.z-icon-purple {
    border: 2px solid rgba(206, 146, 234, 1) !important;
    color: rgba(206, 146, 234, 1) !important;
}

.z-admin-panel {
    min-height: 54vh;
    height: 440px;
}

.z-date .ant-input {
    float: right;
    width: 65%;
    margin-right: 6%;
    height: 32px;
    padding: 4px 11px;
}

.z-admin-tab-small {
    height: 115px;
    border-radius: 7px;
    box-shadow: 0 4.5px 9px rgba(0, 0, 0, 0.1);
}

.z-color-gray2 {
    color: rgba(163, 163, 163, 1) !important;
}

.z-bg-green {
    background: rgba(98, 239, 192, 1);
}

.z-bg-skyblue {
    background: rgba(153, 216, 255, 1);
}

.z-bg-blue {
    background: rgba(133, 174, 255, 1);
}

.z-bg-purple {
    background: rgba(234, 169, 234, 1) !important;
}

.z-admin-i i {
    font-size: 9px;
}

.z-graph-label {
    color: rgba(165, 165, 165, 1);
    font-size: 10px;
    letter-spacing: 4px;
    font-family: ProductSans-Medium;
}

.z-admin-panel-graph {
    min-height: 60vh;
    height: 700px;
}

.z-graph-btn-active {
    background: rgba(242, 64, 119, 1) !important;
    color: #fff !important;
}

.z-graph-btn {
    color: rgba(165, 165, 165, 1);
    border: 1px solid rgba(242, 242, 242, 1);
    width: 28%;
    height: 40px;
    padding: 0 3%;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    margin: 0 5% 0 0;
    background: #fff;
}

.z-width-70 {
    width: 70%;
}

.z-admin-panel2 {
    /*min-height: 153vh;
    height: 1200px;*/
    /*max-height: 153vh;*/
    height: 100%;
}

.z-admin-table .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(230, 231, 232, 0.1);
    max-width: 355px;
}

.z-stripped {
    background: rgba(101, 141, 229, 0.02);
}

.z-admin-table .ant-table-thead > tr > th {
    color: rgba(52, 52, 53, 0.3);
    font-size: 16px;
    font-family: ProductSans-Regular;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(153, 216, 255, 1) !important;
    border-color: rgba(153, 216, 255, 1) !important;
}

.z-table-status {
    color: rgba(99, 99, 99, 1);
    /*font-weight: 700;*/
}

.z-table-name {
    color: rgba(52, 52, 53, 1);
}

.z-table-num {
    color: rgba(52, 52, 53, 0.3);
}

.z-table-text {
    color: rgba(52, 52, 53, 0.8);
}

.z-table-text2 {
    color: rgba(52, 52, 53, 0.6);
}

.z-table-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0;
    border: 0;
}

.z-font-12 {
    font-size: 12px;
}

.z-table-parag {
    margin-bottom: 0;
    font-family: Raleway-Light;
    font-size: 13px;
}

.Product-sans {
    font-family: ProductSans-Medium !important;
    color: rgba(20, 20, 20, 0.5);
}

.z-table-parag2 {
    margin-bottom: 0;
    font-family: Raleway-Regular;
    font-size: 13px;
}

.z-table-parag3 {
    margin-bottom: 0;
    font-family: Raleway-SemiBold;
    font-size: 13px;
}

.z-tablebtn {
    background: rgba(101, 141, 229, 1);
    color: #fff;
    border-radius: 0;
    font-family: ProductSans-Regular;
    border: 0;
    margin-right: 20px;
}

.z-table-nav {
    position: absolute;
    bottom: 20%;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-end; */
    right: 8%;
    width: 17%;
    margin: auto;
    margin-top: 0;
}

.z-color-yellow {
    color: rgba(249, 131, 72, 1);
}

.z-color-green2 {
    color: rgba(82, 221, 139, 1);
}

.z-more-content.z-more-top::before {
    left: 8%;
    top: -22px;
    border-bottom: 12px solid #fff;
}

.z-more-content::before {
    content: "";
    width: 0px;
    height: 0px;
    border: 0.8em solid transparent;
    position: absolute;
}

.z-more-content {
    width: 260px;
    height: 200px;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin: auto;
    background: #fff;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    left: -30px;
    top: 24px;
    padding: 35px 20px 30px 43px;
}

.z-more:hover .z-more-content {
    display: block;
}

.z-more {
    position: relative;
}

.z-more-content p {
    margin-bottom: 0;
    font-size: 13px !important;
    color: rgba(155, 155, 155, 1) !important;
    margin-left: 8% !important;
    font-family: ProductSans-Regular !important;
}

.ant-menu-submenu-title {
    padding-left: 19px !important;
}

.z-pl-33 .ant-menu-item {
    padding-left: 33% !important;
    margin: 4px 0 !important;
}

.z-table-com {
    margin-bottom: 0;
    font-family: ProductSans-Regular;
    font-size: 13px;
    color: rgba(20, 20, 20, 0.5);
}

.z-fill-content {
    width: -webkit-fill-available;
}

.z-tab-btn:hover,
.z-tab-btn:focus {
    color: #fff;
    background-color: rgba(70, 221, 148, 0.83);
    border-color: rgba(64, 219, 152, 1);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.z-tab-btn {
    color: #fff;
    background-color: rgba(64, 219, 152, 1);
    border-color: rgba(64, 219, 152, 1);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.z-ml-25 {
    margin-left: 25%;
}

.z-ml-30 {
    margin-left: 30%;
}

.z-table-search {
    width: 70%;
    float: right;
}

.z-search-label {
    color: rgba(71, 92, 122, 0.6);
    margin-bottom: 0;
    margin-right: 22px;
    font-family: ProductSans-Regular;
}

.z-table-dropdown {
    border-radius: 18px;
    width: 115px;
}

.z-no-mb {
    margin-bottom: 0 !important;
}

.z-table-border {
    border-bottom: 5px solid #fffcfc;
}

.z-form-label hr {
    border: 0;
    border-bottom: 2px solid rgba(99, 174, 255, 1);
    width: 8%;
}

.z-form-label p {
    color: rgba(183, 183, 183, 1);
    font-family: ProductSans-Regular;
    text-align: center;
    margin-bottom: 1%;
    font-size: 16px;
}

.z-text-area {
    background-color: inherit;
    background-image: none;
    border: 1px solid rgba(209, 211, 212, 0.6);
}

.z-text-area2 .ant-select-selection {
    background-color: inherit;
    background-image: none;
    border: 1px solid rgba(209, 211, 212, 0.6);
}

.z-form-avatar {
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}

.z-div-space {
    width: 46%;
    margin-right: 4%;
}

.z-div-space-right {
    width: 46%;
    margin-left: 4%;
}

.z-mb--3 {
    margin-bottom: 3% !important;
}

.z-company-card {
    min-height: 150vh;
}

.z-company-button {
    height: 40px !important;
    width: 190px !important;
}

.z-company-gradient {
    background: rgb(229, 199, 255);
    background: linear-gradient(
        90deg,
        rgba(229, 199, 255, 1) 0%,
        rgba(74, 255, 222, 1) 0%,
        rgba(99, 174, 255, 1) 25%
    ) !important;
    box-shadow: none;
}

.z-admin-label-div {
    position: absolute;
    width: 100%;
    top: 8%;
    padding: 0 15%;
}

.z-package-label-div {
    position: absolute;
    width: 100%;
    top: 8%;
    padding: 0 15%;
}

.z-font-13 {
    font-size: 13px !important;
}

.z-com-div {
    width: 100%;
}

.z-com-div p {
    font-size: 13px;
    color: rgba(196, 196, 196, 1);
    margin-bottom: 0;
    font-family: CircularStd-Book;
}

.z-com-p {
    padding: 8% 17% 0;
}

.z-comp-input {
    background-color: rgba(252, 252, 252, 1);
    background-image: none;
    border: 1px solid rgba(230, 231, 232, 1);
    width: 240px;
    border-radius: 5px;
    font-family: CircularStd-Book;
}

.z-com-card {
    background: rgba(252, 252, 252, 1);
    height: 90px;
    width: 50%;
    margin: auto;
    border: 1px solid rgba(230, 231, 232, 1);
    padding: 4%;
}

.z-radio {
    font-size: 13px;
    font-family: CircularStd-Book;
    color: rgba(150, 150, 150, 1);
}

.ant-input[disabled] {
    color: rgba(150, 150, 150, 1);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}

.z-btn-company2-active {
    background: rgb(99, 174, 255);
    background: linear-gradient(
        31deg,
        rgba(99, 174, 255, 1) 63%,
        rgba(229, 199, 255, 1) 100%,
        rgba(74, 255, 222, 0.9351890414368873) 100%
    ) !important;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 7px 5px rgba(102, 153, 255, 0.3);
    border-color: rgba(99, 174, 255, 0.59) !important;
}

.z-btn-company2 {
    background: rgba(196, 196, 196, 1) !important;
    border: rgba(196, 196, 196, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 7px 5px rgba(102, 153, 255, 0.3);
}

.z-btn-company-active,
.z-btn-company-active:hover,
.z-btn-company-active:focus {
    background: rgba(211, 211, 211, 1);
    border-color: rgba(211, 211, 211, 1);
    box-shadow: 0 2px 4px rgba(81, 206, 143, 0.2);
    cursor: not-allowed;
}

.z-btn-company {
    box-shadow: 0 8px 8px rgba(99, 174, 255, 0.2);
    color: rgba(137, 137, 137, 1) !important;
    border: 1px solid rgba(230, 231, 232, 0.3) !important;
}

.z-border-active {
    border: 2px solid rgba(39, 170, 225, 1) !important;
}

.z-tag {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.z-bg-pink {
    background: #ff7ba1;
}

.z-bg-purple {
    background: #e280ff;
}

.z-bg-black {
    background: #494949;
}

.z-package-header {
    font-size: 18px;
    letter-spacing: -1px;
}

.z-package-input2 {
    background-color: rgba(248, 251, 255, 0.9);
    background-image: none;
    border: 1px solid rgba(248, 251, 255, 0.9);
    width: 130px;
}

.z-package-input2 .ant-select-selection {
    background-color: rgba(248, 251, 255, 0.9);
    background-image: none;
    border: 1px solid rgba(248, 251, 255, 0.9);
    width: 130px;
    color: rgba(150, 150, 150, 1);
    font-family: CircularStd-Book;
}

.z-package-input {
    width: 200px !important;
}

.z-com-p2 {
    padding: 8% 16% 0;
}

.z-p-3 {
    padding: 2% 3%;
}

.z-form-avatar img {
    object-fit: contain !important;
}

.z-company-button:hover,
.z-company-button:active,
.z-company-button:focus {
    color: #fff !important;
}

.ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 1px;
}

.z-empty-div img {
    height: 100%;
}

.z-empty-div {
    height: 150px;
    margin-top: 15%;
    margin-bottom: 15%;
}

.z-width-150 {
    width: 150%;
}

.z-width-50 {
    width: 50%;
}

.z-icn-div img {
    height: 100%;
}

.z-icn-div {
    height: 20px;
    margin-right: 2%;
}

.z-log-modal .ant-modal-content {
    min-height: 5vh;
    height: 195px;
}

.z-verify-btn {
    width: 185px !important;
}

.z-request-card {
    margin-bottom: 6%;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom-right-radius: 0;
}

.z-padding-15 {
    padding: 2% 15%;
}

.z-t-center {
    text-align: center;
}

.z-declined {
    color: #f10606;
    letter-spacing: 0.053rem;
    margin-bottom: 0;
    font-family: ProductSans-Medium;
}

.z-accepted {
    color: #0d8a11;
    letter-spacing: 0.053rem;
    margin-bottom: 0;
    font-family: ProductSans-Medium;
}

.z-head-label {
    color: rgba(86, 86, 86, 1);
    font-size: 24px;
    font-family: ProductSans-Medium;
    margin-bottom: 1%;
    text-align: center;
}

.z-width-64 {
    width: 64%;
}

.z-icon-modal img,
.z-chip img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.z-chip2 {
    margin: 14px 23px auto auto !important;
}

.z-chip {
    height: 56px;
    width: 66px;
    margin-top: 30px;
    margin-left: 18px;
}

.z-icon-modal {
    height: 100px;
    width: 300px;
    margin: 2% auto 2%;
}

.z-checkbox .ant-checkbox-inner {
    border: 1px solid #707070;
    border-radius: 4px;
    height: 14px;
    width: 14px;
}

.z-modal-parag-small2 {
    font-size: 26px !important;
    color: rgb(41, 45, 44) !important;
    text-align: center;
}

.z-modal-parag-small {
    font-size: 14px;
    color: rgb(23, 171, 117);
    margin-left: 6px;
    cursor: pointer;
}

.z-modal-parag {
    font-size: 18px;
    padding: 0 60px;
    text-align: center;
    margin-bottom: 15px;
}

.z-card-debit {
    background: linear-gradient(
        31deg,
        rgba(34, 34, 34, 0.9351890414368873) 0%,
        rgb(234, 233, 233) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    height: 200px;
    width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 16px auto 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    cursor: pointer;
}

.z-textp {
    padding: 5% 16% 0;
}

.z-card-arrowl {
    position: absolute;
    top: 46%;
    left: 9%;
    font-size: 18px;
    z-index: 10;
}

.z-card-arrowR {
    position: absolute;
    top: 46%;
    right: 9%;
    font-size: 18px;
    z-index: 10;
}

.z-navp {
    color: rgba(71, 92, 122, 0.3);
    font-size: 14px;
    font-family: ProductSans-Regular;
    text-align: center;
    margin-bottom: 7px;
}

.z-nav-fold {
    color: #fff !important;
    font-size: 20px !important;
    margin-right: 25px;
}

.z-display-none {
    display: none;
}

.z-package-label {
    font-size: 11px;
    color: #a5a5a5;
    font-family: CircularStd-Book;
}

.z-package-label.text-center {
    text-align: center;
}

.z-package-label2 {
    font-size: 14px;
    color: #727272;
    font-family: ProductSans-Medium;
}

.z-flex-space-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.z-package-p {
    width: 100%;
    padding: 10% 14%;
}

.z-width-68 {
    width: 68%;
}

.ant-upload-list-item-name {
    width: 100px;
}

.z-apply-select .ant-select-selection:hover,
.z-apply-select .ant-select-selection:focus {
    box-shadow: 0 20px 30px #00000008 !important;
    border: 1px solid #7070702b !important;
    border-radius: 10px !important;
    opacity: 1;
}

.z-apply-select .ant-select-selection {
    height: 40px !important;
    font-size: 13px !important;
    background: none;
    color: #000728;
    font-family: MollenPersonalUse-Regular, sans-serif;
    margin-bottom: 0;
    padding: 0 4% 0 0 !important;
    text-transform: capitalize;
    box-shadow: 0 20px 30px #00000008 !important;
    border: 1px solid #7070702b !important;
    border-radius: 10px !important;
    opacity: 1;
}

.z-noborder:hover,
.z-noborder:focus {
    border: 0 !important;
}

.z-mll {
    margin-left: 10px !important;
}

.z-mt-20_ {
    margin-top: 20px;
}

.z-mt-10_ {
    margin-top: 10px;
}

.z-header-dropdown-p {
    color: #d3d3d3;
    font-family: ProductSans-Regular;
    font-size: 14px;
    margin-bottom: 12px;
    margin-top: 0;
    letter-spacing: -1px;
}

.z-header-dropdown {
    color: #fcfcfc;
    font-family: Raleway-Regular;
    font-size: 16px;
    margin-bottom: 18px;
    margin-top: 0;
    letter-spacing: -1px;
}

.z-left {
    text-align: left !important;
}

.z-nav-dropdown {
    margin: 0 0 8% 12%;
}

.z-no-margin {
    margin: 0 !important;
}

.z-badge .ant-badge-count {
    background-image: linear-gradient(
        to right top,
        #60a6fa,
        #00c0ff,
        #00d7ff,
        #00ecff,
        #32fff0
    );
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    top: -7px;
}

.z-p-notification {
    color: rgba(247, 249, 249, 1) !important;
    font-family: ProductSans-Medium !important;
    font-size: 20px !important;
    letter-spacing: -1px !important;
    text-align: center !important;
    margin-left: 0 !important;
}

.z-icon-circle i {
    font-size: 10px;
    color: rgba(122, 122, 122, 1);
}

.z-icon-circle {
    background: rgba(255, 255, 255, 1);
    height: 24px;
    width: 24px;
}

.z-not-p {
    color: rgba(247, 247, 247, 1) !important;
    font-family: ProductSans-Regular !important;
    letter-spacing: 0px;
    font-size: 14px !important;
    margin-left: 8% !important;
}

.z-row-height {
    line-height: 19px;
    width: 90%;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: auto;
}

.z-drawer .ant-drawer-content-wrapper {
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    width: 250px !important;
}

.z-drawer .ant-drawer-content {
    position: relative;
    z-index: 1;
    background-clip: padding-box;
    background: #000728;
    border: 0;
}

.z-drawer .ant-drawer-body {
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
}

.z-show {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
}

.z-show .z-nav-icon {
    height: 35px !important;
    width: 35px !important;
}

.z-show .ant-menu.ant-menu-dark .ant-menu-item-selected,
z-show .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(234, 234, 234, 0.1) !important;
    color: #fff !important;
}

.z-show .ant-menu.ant-menu-dark .ant-menu-item-selected .z-nav-icon,
.z-show
    .ant-menu-submenu-popup.ant-menu-dark
    .ant-menu-item-selected
    .z-nav-icon {
    background: #1890ff;
}

.z-show .logo {
    height: 23px;
    margin: 25px 24px 45px;
}

.z-show .z-nav-hr hr {
    margin: 14px auto 25px;
}

.z-show .ant-menu-item {
    height: 50px !important;
}

.z-height-115 {
    height: 115px;
}

.z-app-modal .ant-modal-body {
    height: 350px;
    overflow: auto;
}

.z-card-debit2 .z-modal-parag-small2 {
    font-size: 18px !important;
}

.z-card-debit2 .z-chip {
    height: 42px;
}

.z-card-debit2 {
    height: 172px;
    width: 310px;
}

.z-wallet-radio {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-wallet-icon {
    height: 125px;
    width: 210px;
    margin: 8% 0 9%;
}

.z-wallet-card-tag {
    color: rgba(253, 253, 253, 0.5);
    font-family: ProductSans-Regular, sans-serif;
    margin-bottom: 0;
    font-size: 18px;
}

.z-wallet-card {
    /*min-height: 10vh;*/
    height: auto;
    padding: 3% 4%;
    width: 100%;
    margin: auto;
    border-radius: 8px;
}

.z-wallet-input {
    height: 110px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    font-size: 54px !important;
    border: none;
    /* background: none; */
    color: rgb(243, 243, 243);
    font-family: ProductSans-Regular;
    margin-bottom: 0;
    padding: 0 12% 0 8% !important;
}

.z-wallet-modal {
    height: 95px;
    width: 300px;
    margin: 0% auto 7%;
}

.z-wallet-amount {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    text-transform: lowercase;
    height: 32px !important;
    font-size: 14px !important;
    padding: 0 5% !important;
}

.z-w-label {
    color: #383737;
    margin-bottom: 0;
    font-size: 13px;
    font-family: CircularStd-Book;
}

.z-w-radio {
    font-size: 13px;
    font-family: ProductSans-Regular;
}

.z-w-modal {
    padding: 0 8%;
}

.z-radio-scroll:hover {
    overflow-y: auto;
}

.z-radio-scroll {
    height: 80px;
    overflow-y: hidden;
    width: 100%;
}

.check-circle {
    position: absolute;
    right: 20%;
    top: 25%;
    font-size: 20px;
    background: green;
    color: white;
    border-radius: 20px;
}

.close-white {
    font-size: 12px !important;
    color: white;
    margin-bottom: 15px;
    margin-right: 5px;
}

.z-req-red {
    background: #8b2029 !important;
}

.z-req-yellow {
    background: goldenrod !important;
}

.z-modal-buser i {
    color: black !important;
}

.z-modal-buser {
    background: rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0 3px 4px rgba(140, 140, 140, 0.45) !important;
}

.z-muser {
    font-size: 11px !important;
    width: 51% !important;
}

.input-margin {
    margin-bottom: 10px;
    margin-left: 10px;
}

.color-red {
    color: red;
}

/*
background: linear-gradient(90deg, #1986d8, #7b9cc2)
*/

.table-index {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: #1fa5ea1a;
    border: 0;
    color: #1fa5ea;
    text-align: center;
    font: 17px MollenPersonalUse-Bold, sans-serif;
}

.profile-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20px !important;
    width: 20px !important;
    background: #fcfdff;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 0 solid #f1f2f2 !important;
    cursor: pointer;
}

.profile-form {
    height: 42px !important;
}

.profile-form input.ant-calendar-picker-input {
    height: 42px !important;
}

.z-backlist-input {
    margin-top: 20px;
    width: 100%;
}

.z-custom4 .ant-modal-content {
    min-height: 400px;
    height: 420px;
}

.z-table-btn {
    color: #fff;
    border-radius: 0;
    font-family: ProductSans-Regular;
    border: 0;
    margin-top: 4px;
}

.z-table-btn.success {
    background-color: rgba(70, 221, 148, 0.83);
    color: #fff;
    margin-right: 20px;
}

.z-table-btn.success:hover,
.z-tab-btn.success:focus {
    color: #fff;
}

.z-tab-btn.danger {
    color: #fff;
    background-color: #ff7875;
    border-color: #ff7875;
}

.z-table-btn.danger:hover,
.z-tab-btn.danger:focus {
    color: #fff;
}

.z-com-p2.loan-package-modal {
    padding-top: 0;
}

.z-custom5 .ant-modal-content {
    min-height: 80vh;
    border-radius: 69px;
}

.z-custom5 .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #f9f9f9;
    border-bottom: 0;
    border-radius: 69px 69px 0 0;
    min-height: 13vh;
    height: 90px;
}

.z-custom5 .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 69px 69px;
    background: #69b9f9;
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 13vh;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-custom5 .ant-modal-title {
    color: #f9f9f9;
}

.z-custom5 .ant-modal-close-x {
    font-size: 12px;
}

.z-custom5 {
    width: 35% !important;
    border-radius: 69px;
}

.z-apply-label-large {
    font-size: 30px;
    font-family: MollenPersonalUse-Regular, sans-serif;
    text-align: left;
    letter-spacing: 0;
    color: #000728;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.z-apply-label-large.z-label-custom {
    align-items: start;
}
.z-refarral-section {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.z-referral-code {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    border: 1px solid;
    justify-content: center;
    padding: 0.4rem;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
}

.z-referral-copy-btn {
    cursor: pointer;
    margin-left: 0.4rem;
}
.z-referral-card-group {
    display: flex;
    gap: 3%;
}

.wallet-card.referral-card {
    width: 350px;
    height: 300px;
}

.card-text-title {
    font-size: 17px;
    font-family: MollenPersonalUse-Regular, sans-serif;
    text-align: center;
    letter-spacing: 0;
    color: #000728;
    opacity: 0.8;
}

.card-btn-group {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.z-mandate-radio-group {
    margin-bottom: 10px;
}

.z-mandate-radio-button {
    width: 65vw;
    height: 100%;
    margin-bottom: 15px;
}

.z-mandate-radio-button b {
    font-size: 18px;
    font-weight: 800;
}

.z-mandate-radio-button p {
    font-size: 14px;
    margin-bottom: 0;
}

.z-profile-label span {
    font-size: 15px;
    color: red;
    opacity: 40%;
}

.z-mandate-response {
    font-size: 1.1rem;
    width: 90%;
    font-weight: bold;
    margin-top: 13%;
}

.z-apply-label-small.resend-code-text {
    font-size: 14px;
}

.verify-bvn-btn {
    position: absolute;
    top: 50%;
    right: 6%;
    border: 0;
    font-weight: 700;
    background: none;
    font-size: 14px;
    color: rgb(23, 171, 117);
}

.verify-bvn-btn :disabled {
    opacity: 40%
}