@font-face {
  font-family: MollenPersonalUse-Regular;
  src: url("../../assets/font/MollenPersonalUse/MollenPersonalUse-Regular.otf") format("opentype");
}

.card-title {
  text-align: left;
  font-family: MollenPersonalUse-Regular, sans-serif;
  font-size: 18px/22px ;
  letter-spacing: -0.36px;
  color: #000728;
  opacity: 0.45;
}

.wallet-card {
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  background-image: url("../../assets/images/dashboard/wallet.png");
  background-color: #28428A;
  width: 404px;
  border-radius: 20px;
}
.add-bank-modal .ant-modal-content {
  border-radius: 40px;
}

.add-bank-modal .ant-modal-header {
  border-radius: 40px 40px 0 0;
}
.trans-header {
  color: #000728;
  font-family: MollenPersonalUse-Bold, sans-serif;
  font-size: 15px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  opacity: 0.9;
}

.trans-icon {
  height: 15px;
  font-size: 9px;
  opacity: 1;
  color: #000728;
}
.trans-row {
  margin-bottom: 0;
  color: #000728;
  font-size: 13px;
  font-family: MollenPersonalUse-Bold, sans-serif;
}